.main-success-team-container {

}

.inner-success-team-container {
  max-width: 1300px;

  padding: 20px;
  margin: auto;
  margin: auto;

}

.success-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  gap: 15px;
  padding: 10px 10px;
}

.card-img-bggggg {
  display: flex;
  justify-content: center;
  background-blend-mode: luminosity;
}
.card-img-bggggg img {
  height: 100%;
  width: 100%;
}
.sm-success-text {
  font-size: 15px;
 
  color: #757a7b;
  word-spacing: 2px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  display: flex;
  font-weight: 600;
  align-items: center;
}
.Tittle-success-text {
  font-size: 40px;
  font-weight: 600;
  font-family: Syne;
  justify-content: center;
  align-items: center;
  margin: 4px 0px 0px;
  text-align: center;
}

.success-team-image-container{
  /* display: flex;
  */
  flex-direction: row;

  overflow: hidden;
  background-color: var(--text);

  padding: 10px 10px;
}
.success-team-image-container>div {
  flex: 1;
  margin-bottom: 10px;
  
}
/* .success-team-image-container {
  display: flex;
  gap: 20px;
  flex-direction: row;
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  padding-bottom: 40px;
} */

.success-card-team-container {
  display: flex;
  flex-direction: column;
  padding: 5px;

  
}
.out {
  display: flex;
  justify-content: center;
  align-items: center;

  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}



/* .team-info {
  width: 100%;

  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 248, 248);
  margin-top: -80px;

  color: #333;

  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 20px 30px  20px;
  margin-bottom: 10px;
}
.team-info:hover{
  background-color: black;
  color: white;
  position: relative;
}
    .hided{
      display: none;
    }
    .team-info:hover
    .hided{
      display: block;
  }
.hided{
  padding: 10px 0px;

  align-items: center;
  text-align: center
  ;
} */


/* 
.team-info {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 248, 248);
  margin-top: -80px;
  color: #333;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}

.team-info::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0) 100%);
  top: 100%;
  left: 0;
  transition: top 0.4s ease-in-out;
  z-index: 0;
}

.team-info:hover::before {
  top: 0;
}

.team-info:hover {
  color: white;
}

.team-info * {
  position: relative;
  z-index: 1;
}

.hided {
  display: none;
  padding: 10px 0px;
  text-align: center;
}

.team-info:hover .hided {
  display: block;
}
 */


 .team-info {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 248, 248);
  margin-top: -80px;
  color: #333;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}
.team-info .hided {
  
    opacity: 0;
    padding: 10px 0px;
    text-align: center;
    transition: opacity 0.4s ease-in-out;
    display: none;
    color: red;
  

}
.team-info::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0.799) 100%);
  top: 100%;
  left: 0;
  transition: transform 0.4s ease-in-out;
  z-index: 0;
}
.team-info:hover .hided{
 

  
    display:block;
    color: white !important;
  
 
  

}
.team-info:hover::before {
  transform: translateY(-100%);
}

.team-info:hover {
  color: white;
}

.team-info * {
  position: relative;
  z-index: 1;
}

/* .hided {
  opacity: 0;
  padding: 10px 0px;
  text-align: center;
  transition: opacity 0.4s ease-in-out;
} */

.team-info:hover .hided {
  opacity: 1;
}

/* ----------------- */


.team-info h3 {
  font-size: 20px;

  margin: 10px 0 5px;
}

.team-info p {
  /* color: #777; */
  font-size: 14px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-top: 10px;

}

.social-icons a {
  padding: 12px 15px;
  align-items: center;
  text-align: center;
  border: 2px solid #f0c040;
  border-radius: 50%;
  color: #333;
  font-size: 18px;
  transition: 0.2s;


  align-items: center;
}

.social-icons a:hover {
  background-color: #f0c040;
  color: var(--text);

}

/* .team-info:hover ~ .social-icons a  {
color: var(--primary) !important;
background-color: red;
} */

@media (max-width: 1060px) {
  .success-team-image-container {
    /* display: flex;
  
    margin-bottom: 10px;
    flex-direction: column;

    margin: auto;
    justify-content: center;
    padding-bottom: 40px; */


    /* display: grid;
    grid-template-columns: 1fr; */
  
  }
  .success-card-team-container img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
