.footer-policy-container{
background-color: #222222;

}
.footer-policy-main-container{
max-width: 1300px;
margin: auto;
padding: 20px 30px;

}
.inner-footer-policy-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
gap: 10px;
}
.left-footer-policy-bottom{
    color: var(--text);
   

}
.left-footer-policy-bottom span{
    color: var(--primary);
    font-size: 20px;
}

.right-footer-policy-bottom{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    color: var(--text);

    
}
.right-footer-policy-bottom>div a{
    color: white;
}
.right-footer-policy-bottom>div a:hover{
    color: var(--primary);
    transition: 1s;
}
.right-footer-policy-bottom>div:nth-child(1){
    border-right: 2px solid white;
    padding: 0px 20px;
}
.right-footer-policy-bottom>div:nth-child(2){

    padding: 0px 20px;
}

/*  top buttom css */

.home-top-button{

    background-color: #000;
    display: flex;
    color: var(--primary);
    border:  1px solid;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: fixed;
    bottom: 40px;
    right: 30px;
    padding: 5px;
    font-size: 20px;
    z-index: 2;
    
}

/* @media(max-width:400px){

    .footer-policy-main-container{
    
        padding: 20px 10px;
        
        }
        .right-footer-policy-bottom{
            display: flex;
            flex-direction: row;
         
        
            color: var(--text);
        
            
        }
        .right-footer-policy-bottom>div:nth-child(1){
            border-right: 2px solid white;
            padding: 0px 20px 0px 0px;
        }
} */

@media(max-width:650px){
    .footer-policy-main-container{
    
        padding: 20px 5px;
        
        }
        .left-footer-policy-bottom{
            font-size: 15px;
        }
        .right-footer-policy-bottom{
            display: flex;
            flex-direction: row;
            font-size: 15px;
         
        
            color: var(--text);
        
            
        }
        .left-footer-policy-bottom span{
            color: var(--primary);
            font-size: 16px;
        }
    .right-footer-policy-bottom>div:nth-child(1){
        border-right: 2px solid white;
        padding: 0px 15px 0px 0px;
    }
}