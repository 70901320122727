.mobile-application-main-container{

   
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.mobile-application-main-container{

    /* padding: 120px 0px 120px; */
    /* margin-top: -50px; */
}
.inner-mobile-application-main-container{

    max-width: 1300px;
    margin: auto;
     padding: 120px 0px 120px;
}

.mobile-service-container{

    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 0px 40px;

}
.mobile-services-menu-container{
   
    border: 2px solid var(--primary);
 

}
.mobile-menu-heading-text{


    background-color: var(--primary);
    padding: 14px 50px;
    color: var(--main);
    font-size: 20px;
    font-family: Syne;
    font-weight: 600;
}
.mobile-menu{
    padding: 25px 25px  20px 25px;
}


.mobile-menu > a {
    background-color: var(--main);
}
.mobile-menu-active{
    background-color: var(--main) !important;
    color: #f5f3EE !important;
}
.mobile-services-menu-container a{
    text-decoration: none;
    color: var(--main);
    /* padding: 0px 30px; */
}
.mobile-services-menu-container a i{
    color: var(--primary);
}
.mobile-service-menu{
    display: flex;
    background-color: #f5f3EE;
    color: var(--main);
  
    font-size: 16px;
    font-weight: 600;
    padding: 14px 25px 15px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}
.mobile-service-menu:hover{
    /* padding: 0px 0px; */
    transform: 1s;
    background-color: var(--main);
    color:var(--text);
}

/* left -menu  -2*/

.service-menu-container-left-2{

    margin-top: 20px;
    height: 400px;
    width: 100%;
    padding: 20px 30px;
    background-color: var(--primary);
    /* background: rgb(220,199,37);
    background: linear-gradient(0deg, rgba(220,199,37,0.9389880952380952) 20%, rgb(237, 199, 123) 100%);
     */
    
    background-image:url("https://pitoon.pixcelsthemes.com/main-html/assets/images/resources/banner-one-img.png");

    background-repeat:no-repeat;
    background-position: right;

  

}
.mobile-menu-2-inner-text{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 20px 0px;
    display: flex;
 
    
}
.mobile-menu-2-inner-text div{
    max-width: 50%;
    font-size: 24px ;
    font-family: Syne;
    font-weight: 600;

}
.mobile-menu-btn-contact a{
    text-decoration: none;
    color: var(--text);
}
.mobile-menu-btn-contact{
    padding: 10px 40px;
    background-color: var(--text);
    color:var(--text);
    font-weight: 600;
  
    border: none;
    border-radius: 3px;
    position: relative;
    transition: color 0.4s ease-in-out;
    overflow: hidden;
}



  
  .mobile-menu-btn-contact::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main);
    color: white;
    transition: transform 0.4s ease-in-out;
  }
  
  .mobile-menu-btn-contact::after {
    content: "Contact";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mobile-menu-btn-contact:hover::after {
    transform: translateX(100%);
  }
  
  .mobile-menu-btn-contact:hover {
    color: var(--main);
  }
  



/* ------------- */




.service-menu-container-left-3{

    margin-top: 15px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--primary);
    font-size:16px;
   
    padding: 19px 29px;
    color: var(--secondary);
 
    font-weight: 600;
    align-items: center;
    gap: 20px;
    background-color: rgb(255, 251, 247);
}



.svc-menu-card-icon{
background-color: var(--primary);
padding: 15px 20px;
border-radius: 50%;
color: var(--main) !important;

}
.svc-menu-card-icon:hover{
    background-color: var(--main);
    transition: 1s;
    color: var(--text) !important;
}
.svc-menu-card-icon i{
}
/* Right-menu-container-Style */

.Mobile-application-service-details{

    padding: 0px 10px;

}
.Mobile-application-service-images img{
    height: 350px;
    width: 100%;

}
.mobile-service-headling{
    margin: 21px 0px;
    font-size: 35px;
    font-family: Syne;
    color: var(--main);
    font-weight: 600;
}
.mobile-service-desc{
  
    font-size: 16px;
    color:var(--secondary);

    line-height: 30px;
}
.mobile-service-help-headling{
    color: var(--primary);
    margin: 27px 0px 29px;
    font-size: 24px;
    font-weight: 600;
    font-family: Syne;
}
.mobile-service-help-desc{
   
    font-size: 16px;
    color:var(--secondary);
 
    line-height: 30px;
}

.child-pitoon-container p{

    font-size: 30px;
    font-family: Syne;
    font-weight: 600;
    color:var(--main);
    margin: 42px 0px 30px;
}

.pitoon-card{
    display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 10px;
  
}
.pitoon-card>div{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    padding: 10px 20px;
    gap: 10px;
    align-items: center;
    font-size:16px;
    color: var(--secondary);

    font-weight: 600;
    background-color: antiquewhite;
}
.pitton-card-icon{
    background-color: var(--primary);
    padding: 15px 20px;
    border-radius: 50%;
    align-items: center;
    color: var(--main) !important;

}

.pitton-card-icon:hover{
 

    background-color: var(--main);
    transition: 1s;
    color: var(--text) !important;
}

.mobile-service-benifit-container{

    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 30px 0px;
}
.mobile-service-benifit-container>div{
    flex: 1;
    
}
.mobile-service-benifit-container .img-container img{
    border-radius: 50%;

}
.mobile-svc-benifit-details{
    padding: 30px 0px 0px 0px;
}
.mobile-svc-benifit-details .p1{
 font-size: 30px; font-family: Syne; font-weight: 600;
 color: var(--main);
 
}
.mobile-svc-benifit-details .p2{
    margin: 26px 0px 35px;
    font-size: 18px; color: var(--primary); font-weight: 600;
}
.mobile-svc-benifit-details ul li{
    list-style: none;
    
    font-size: 16px; color: var(--main);
    padding:5px 0px;

    font-weight: 500;
}
.mobile-svc-benifit-details ul li i{
    color: var(--primary);
    margin-right: 10px;
}
.mobile-svc-benofit-bottom-card{
    margin-top: 50px;
    background-color: #f5f3EE;
    padding: 35px 35px 0px 30px;
    display: flex;
    justify-content: space-between;


}
.mobile-svc-benofit-bottom-card p{
    font-size: 22px ; font-family: Syne; color: var(--main);
    font-weight: 600;
    max-width: 70%;
    line-height: 35px;
    margin-bottom: 25px;
}
.mobile-svc-benofit-bottom-carr-img{
    /* background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/services/services-details-importance-img.png");
    background-position: right;
    background-repeat: no-repeat; */
}
.mobile-svc-benofit-bottom-carr-img img{
    margin-top: -130px;
    display: flex;

}


/* Updates CSS According To Content  Start*/




/* For Mobile App service page  start */
.Mobile-service-reading-text{
    font-size: 25px;
    color: var(--main);
    font-weight: 500;
    padding: 10px 0px;
}

.Mobile-service-sm-reading-text{
    font-size: 20px;
    color: var(--main);
    font-weight: 500;
    padding: 10px 0px;
    display: flex;
    
}
.Mobile-service-sm-reading-text div{
    width: 20px;
   
}
.mobile-service-data-list-container{
    padding: 0px 0px 0px 20px;
}
.mobile-service-data-list-container li{

    color: var(--secondary);
    line-height: 30px;
    

}
.mobile-service-data-list-container li b{
    font-weight: 500 !important;
}
/* For Mobile App service page  End */









/* Updates CSS According To Content  End*/









































@media(max-width:996px){
    .mobile-service-container{

        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 0px 30px;
    
    }
    .mobile-service-benifit-container{
        flex-direction:column;
    }
}
@media(max-width:768px){

    .mobile-svc-benofit-bottom-carr-img img{
        margin-top: -130px;
        display: flex;
       /* width: 80%; */
    
    }
    .mobile-service-container{

  
        padding: 0px 5px;
    
    }
    .mobile-svc-benofit-bottom-card{
    
        padding: 35px 35px 20px 30px;
   
    
    
    }
    .pitoon-card{
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    .mobile-svc-benofit-bottom-carr-img{
        display: none;
    }
    /* .mobile-svc-benofit-bottom-card p{
        font-size: 15px ; font-family: Syne; color: var(--main);
        font-weight: 600;
        max-width: 40%;
        line-height:30px;
        margin-bottom: 25px;
    } */
}