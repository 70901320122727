.testimonial-review-background-container{

    background-color: var(--main);
}
.main-testimonial-review-container{

    max-width: 1300px;
    margin: auto;
    padding: 100px 110px;
}
.inner-testimonial-review-container{
    display: flex !important;
    flex-direction: row ;
    padding: 10px;
    gap: 10px;
 
  
}
.inner-testimonial-review-container>div:nth-child(1){

  background: #fff;

  height: 100px;
  width:  100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--primary);
  border-radius: 50%;
padding: 10px;

 
}
.inner-testimonial-review-container>div:nth-child(1) img{
  width: 100%;
}
.inner-testimonial-review-container>div:nth-child(2){
 
/* background-color: rgba(0, 0, 0, 0.285); */
color: var(--text);
padding: 0px 10px;
font-size: 25px;
font-weight: 600;
line-height: 40px;
font-family: Syne;
flex: 1px;
  }

.main-testimonial-review-container>div:nth-child(2){

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: wheat;
}
  .ts-review-username{

    font-family: Syne;
    color:var(--primary);
    font-size: 20px;
    font-weight: 600;
    margin-top: 40px;

  }
.ts-review-username a{
  color: var(--primary);
  cursor: pointer;
}
  .ts-review-username span{
    color: var(--secondary);
    font-size: 15px;
    padding-left: 25px;




  }
  .ts-slide-customer-arrow>div:nth-child(1){
    display: flex;
    flex-direction: row;
  }
  .ts-slide-customer-arrow>div:nth-child(1) img{
 height: 50px;
 width: 50px;
 border-radius: 50%;
 margin-right: 10px;
 mix-blend-mode: luminosity;
  }
  .ts-slide-customer-arrow>div:nth-child(2){
    display: flex;
    gap: 10px;
    padding: 10px;
  

  }
  .ts-slide-customer-arrow>div:nth-child(2) div{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgb(66, 22, 22);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .ts-slide-customer-arrow>div:nth-child(2) div:hover{
    background-color: var(--primary);
    color: var(--main);
    transition: 1s;
    border-radius: 50%;
  }
  @media(max-width:768px){
    .main-testimonial-review-container{

      
        padding: 50px 15px;
    }

    .inner-testimonial-review-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
      
    }
    .inner-testimonial-review-container>div:nth-child(2){
 
      /* background-color: rgba(0, 0, 0, 0.285); */
      color: var(--text);
      padding: 0px 10px;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      font-family: Syne;
        }
      
  }