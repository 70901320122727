.mm{
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  height: 100%;
}
.home-header{
    background: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/backgrounds/slider-1-3.jpg");
    background-position: center;
    background-size: cover;
    background-color: red;
    width: 100%;
    overflow: hidden;
    align-items: center;
    min-height: 850px;
  }
  
  .home-header-main{
    background: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/shapes/main-slider-shape-2.png");
    /* background: rgba(3, 24, 34, 0.753); */
        background: rgba(3, 24, 34, 0.806);

    background-repeat: no-repeat;
    background-position: right;
    /* top: 0; */
    /* background-position-y:0 ; */


  
    color: #fff;
    padding: 250px 100px;
    min-height: 850px;
 
}

.home-header-heading-1{
    position: relative;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 2px solid var(--primary);
    width: max-content;
    padding-bottom: 5px;
}

.home-header-animation-1{
    animation: fadeInDown;
    animation-duration: 1s;
}

/* .home-header-animation-1:focus {
  outline: none;
  border: 2px solid #007bff;
  animation: focusAnimation 1s ease-in-out;
} */




.home-header-animation-2{
    animation: fadeInUpBig 1s ;
    /* animation-duration: .8s; */
}
.home-header-heading-3,
.home-header-heading-2{
    font-size: 90px;
    font-family: "Syne", serif;
    font-weight: 600;
    line-height: .9;
    padding: 20px 0 40px;
    max-width: 900px;
}
/* .home-header-heading-3{
    font-size: 60px;
    padding: 20px 0 0;

} */

.home-header-heading-2 span{
    color: var(--primary);

}


.home-header-link{
  background-color: var(--text);
  color: #000;
  text-decoration: none;
  padding: 15px 40px;
  font-weight: 800;
  font-size: 16px;
  cursor: pointer !important;
  position: relative;
  transition: color 0.4s ease-in-out;
  overflow: hidden;
  width: max-content;
}






.home-header-link::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    color:var(--main);
    transition: transform 0.4s ease-in-out;
  }
  
  .home-header-link::after {
    content: "Discover more";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .home-header-link:hover::after {
    transform: translateX(100%);
  }
/* 

.home-header-animation-2 .home-header-link:hover{
    background-color: white;
    color: var(--main);

} */

/* 

.home-header-link{
    padding: 16px 50px 17px;
    background-color: var(--text);
    font-weight: 500;
    font-family: Syne;
    border: none;
    color: white; 
    font-size: 16px;
    position: relative;
    transition: color 0.4s ease-in-out;
    overflow: hidden;
  }
  
  .home-header-link::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main);
    color: white;
    transition: transform 0.4s ease-in-out;
  }
  
  .home-header-link::after {
    content: "Descover More";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .home-header-link:hover::after {
    transform: translateX(100%);
  }
  
  .home-header-link:hover {
    color: var(--main);
  }   */
    


@media only screen and (max-width: 900px) {

    
.home-header-main{
    background: rgba(3, 24, 34, 0.753);
    color: #fff;
    padding: 250px 30px 200px;
}
    

.home-header-heading-2{
    font-size: 50px;
}
    
}


@media(max-width:768px){
  .home-header{

    min-height: 720px;
  }
  
  .home-header-main{

    min-height: 720px;
 
}

.home-header-heading-2{
  font-size: 40px;
}
}

