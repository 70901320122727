@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
:root {
  --main: #04171a;
  --primary: #f9be17;
  --card_bg: #f5f3ee;
  --text: #ffffff;
  --secondary: #757a7b;
}

body {
  /* font-family: "Syne", serif; */
  font-family: "Manrope";
}

.web-nav .navbar {
  background-color: transparent;
  backdrop-filter: blur(10px);
}

.main-bttom-agency-image-opacity {
  opacity: 0;
}

.main-bttom-agency-image-animation {
  animation: fadeInLeft 1.5s;
}

.fade-up-swing {
  animation: fadeInUp;
  animation-duration: 1s;
}
.fade-up-swing1 {
  animation:zoomIn;
  animation-duration: 1s;
}

.fade-up-fade-up {
  animation:fadeInLeft 1.5s;
  /* animation-duration: 1.5s; */
}
.fade-right {
  animation:fadeInRight 1.5s;
  /* animation-duration: 1.5s; */
}
.fade-up-animation-opacity {
  opacity: 0;
}
.flip-y{

  animation:heartBeat 1s;
}

.fade-up-fade-upOneS {
  animation:fadeInLeft 1s;
  /* animation-duration: 1.5s; */
}
.card-left-animation1{

  animation:fadeInLeft 2.5s;
}
.card-left-animation2{

  animation:fadeInLeft 1.7s;
}
.card-left-animation3{

  animation:fadeInLeft 0.8s;
}

/* for success team card container */

.Scard-left-animation1{

  animation:fadeInLeft 2.5s;
}
.Scard-left-animation2{

  animation:fadeInLeft 1.5s;
}
.Scard-left-animation3{

  animation:fadeInLeft 0.8s;
}



/* loader ------------------------------------------------------------- */


.loader {
  width: 88px;
  height: 12px;
  --_g: no-repeat radial-gradient(farthest-side,#000000 94%,#ffffff00);
  background:
    var(--_g) 25% 0,
    var(--_g) 75% 0;
  background-size: 12px 12px;
  position: relative;
  animation: l24-0 1s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  height: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000000;
  inset: 0;
  margin: auto;
  animation: l24-1 1s cubic-bezier(0.5,300,0.5,-300) infinite;
}


@keyframes l24-0 {
  0%,24%  {background-position: 25% 0,75% 0}
  40%     {background-position: 25% 0,85% 0}
  50%,72% {background-position: 25% 0,75% 0}
  90%     {background-position: 15% 0,75% 0}
  100%    {background-position: 25% 0,75% 0}
}
@keyframes l24-1 {
  100% {transform:translate(0.1px)}
}

.loading-component{
  position: fixed;
  z-index: 8;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: 700;
  background-color: #fff;
}










@media (max-width:768px) {

  .card-left-animation1{

    animation:fadeInLeft 1.5s;
  }
  .card-left-animation2{
  
    animation:fadeInLeft 1.5s;
  }
  .card-left-animation3{
  
    animation:fadeInLeft 1.5s;
  }
  
}