.error-page-main-container{
    background-color: white;

}
.inner-error-page-main-container{

max-width: 1300px;
margin: auto;
padding: 20px 30px;
}
.inner-error-page-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin-bottom: 50px;
}
.inner-error-page-main-container>div:nth-child(1){
    display: flex;
    padding: 10px 30px;
  
}
.inner-error-page-main-container div img{
    width: 100%;
}

.inner-error-page-main-container>p:nth-child(2){

    color:var(--main);
    font-size: 40px;
    font-weight: 600;
    font-family: Syne;
    margin: 0px 0px 15px;
}
.inner-error-page-main-container>p:nth-child(3){

    color:var(--secondary);
    font-size: 20px;
    font-weight: 500;

    margin: 0px 0px 15px;
}
.error-page-btn button{
    background-color: var(--primary);
    color: var(--main);
    font-weight: 600;
  
    font-size: 15px;
    padding: 15px 25px;
    border: none;
  

}

@media(max-width:768px){
    
.inner-error-page-main-container>p:nth-child(2){

    color:var(--main);
    font-size: 30px;
}

.inner-error-page-main-container>p:nth-child(3){

    font-size: 15px;
}

}