.user-review-mian-container{

    background-color: white;

    padding: 20px;

}
.review-heading-container{

    padding: 20px 10px 40px 10px ;
 
    /* padding: 30px 10px 50px 10px; */
 
}
.inner-review-heading-container{
    justify-content: center;
    text-align: center;

}
.inner-review-heading-container .p1{

    font-size: 15px;
   
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    color:#757A7B;
    letter-spacing: 1.5px ;
    word-spacing: 1px;
    
    display: flex;
    justify-content: center;
    align-items: center;

} 
.inner-review-heading-container .p2{
    color: var(--main);
    font-size: 50px;
    font-family: Syne;
    margin: 4px 0px 0px;
    font-weight: 600;
    padding: 10px 0px;
   
}
.inner-user-review-mian-container{

    max-width: 1200px;
    margin: auto;

    
    /* display: flex; */
  
    /* flex-wrap: wrap; */

    gap: 15px;

}
.user-review-card-left{
    display: flex;
    flex-direction: row;

    flex:1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 10px 30px;
 
}
.user-review-card-left{

    display: flex !important;
  
    gap: 10px;
    padding:20px;
    border: 2px solid var(--primary);
    margin-right: 2px;
    /* background-color: green; */
}
.user-profile-img{

    padding: 20px 10px 50px 30px;
    padding: 10px;
    border: 1px solid black;
 
}
.user-profile-img div{

    /* padding: 5px; */
}

.user-profile-img div img{
    width: 120px;
    height: 120px;

    border-radius: 50%;
    border:  solid var(--primary);
    padding: 5px;
    display: flex;
}
.user-review-text{


    padding: 20px 20px 20px 10px;
    border: 1px solid black;
}
.user-review-text .user-nm{
    font-size: 24px;
    font-weight:600;
    font-family: Syne;
    padding: 10px 0px;

}
.user-review-text .user-desc{

    color:var(--secondary);
    padding: 20px 0px;
    font-size: 16px;


    line-height: 35px;
    font-weight: 600;
    
}

.user-review-text i{

    color: var(--primary);

}

@media(max-width:968px){
    .inner-user-review-mian-container{

        max-width: 1200px;
        margin: auto;
    
        display: flex;
       flex-direction: column;
    
        gap: 15px;
    
    }
}

@media(max-width:768px){



    .user-review-card-left{

        display: flex;
        flex-direction: column;
        gap: 10px;
    
        padding: 15px 10px;
    }
    .user-profile-img{

        padding: 10px 10px 10px 10px;
     
    }
    .user-review-text .user-desc{
        line-height: 25px;
        
    }
    .user-review-text{
        padding: 0px 10px 10px 10px;
    }
   
}

.card-review{

    /* display: flex; */

    /* border: 1px solid var(--primary); */
    padding: 40px  50px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
   
}
.review-shadow{

  
    padding: 10px 10px;
}
.inner-card-review{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
 
}
.inner-card-review div img{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    padding: 2px;

    border: 1px solid var(--primary);
}
.card-review>div:nth-child(2){
    padding: 20px 0px;
    width: 100%;
}
.inner-card-review>div:nth-child(2) p:nth-child(1){
    color: var(--main);
    font-weight: 600;
    font-family: Syne;
    font-size: 18px;

}
.inner-card-review>div:nth-child(2) p:nth-child(2){
    color: var(--primary);
    font-weight: 600;
    font-family: Syne;
    font-size: 16px;
    margin-top: 5px;

}
@media(max-width:768px){

    .card-review{
        padding: 30px  20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
       
    }
    .inner-review-heading-container .p2{
        color: var(--main);
        font-size: 35px;
        font-family: Syne;
        margin: 4px 0px 0px;
        font-weight: 600;
        padding: 0px 0px;
       
    }
}