.digital-mian-faq{

    background-color: white;
        padding-top: 20px;
    
    }
    
    .inner-digital-mian-faq{
    
        max-width: 1200px;
        margin: auto;
        background-color: white;
    
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        padding:80px 15px;
        gap: 25px;
        /* justify-content: center;
        align-items: center; */
        /* justify-content: space-between; */
    
    }
    .details-left-top-digital-faq{
        padding: 20px 0px;
    }



    
    
    .details-left-top-digital-faq .p1-text{
    
        font-size: 15px;
    
        color :#757A7B;
        text-transform: capitalize;
        text-transform: uppercase;
        letter-spacing: 1.1px;
    }
    .details-left-top-digital-faq .head{
    
        font-size: 50px;
        font-family: Syne;
        margin: 4px 0px 0px;
        color: #04171A;
        font-weight: 600;
    }
    .details-left-top-digital-faq .desc{
    
            font-size: 14px;
         
            color: #757A7B;
            padding: 20px 0px;
    }
    
    .inner-details-left-digital-faq-lift{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
    }
    .inner-details-left-digital-faq-lift div p i{
        color: var(--primary);
        margin-right: 10px;
        /* font-size: 18px; */
      
    
    }
    .inner-details-left-digital-faq-lift div p {
    
        padding: 8px 0px;
        font-size: 16px;
  
        color: #04171A;
     
        display: flex;
    
    }
    .client-project-slider-contaier{
    
        background-color: var(--primary);
        height: 200px;
        width: 200px;
        /* padding: 8px 10px; */
        padding: 7px 15px 5px 5px;
    }
    .inner-client-project-slider-contaier{
    
        height: 100%;
        width: 100%;
        background-color: white;
    }
    
    .inner-client-project-slider-contaier>div:nth-child(1){

        padding: 5px 15px;
        font-size: 35px;
        color: var(--primary);

    }
    .inner-client-project-slider-contaier>div:nth-child(2) p:nth-child(1){



      
        font-size: 35px;
        font-weight: 500;
        padding: 0px 10px;

    }
    .inner-client-project-slider-contaier>div:nth-child(2) p:nth-child(2){



      
        font-size: 15px;
        font-weight: 600;
        padding: 0px 10px;
        font-family: Syne;
        color:var(--secondary)

    }
    
    
    .Project-iconnss img{
        height: 75px;

    }
    /* right-side cotainer */
    
    
    .details-right-digital-faq{
        max-height: 450px;
        overflow-y: scroll;
        padding: 0px 20px;
        border: 1px solid rgba(0, 0, 0, 0.247);
    }
    
    .inner-details-right-digital-faq{
        display: none;
    }
    



    /* .details-right-digital-faq-container{ */
    .details-right-digital-faq>div{
        padding: 25px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.331);
    }
    
    .details-right-digital-faq>div:last-child{
        border: none;
    }


    .details-right-digital-faq-question{
        font-weight: 600;
        font-size: 18px;
        font-family: Syne;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .details-right-digital-faq-question-active{
        color: var(--primary);
    }

    .details-right-digital-faq-question .fa-plus{
        color: var(--primary);
    }
    
    .details-right-digital-faq-question .fa-minus{
        color: var(--main);
    }

    .details-right-digital-faq-answer{
        margin-top: 10px;
 
        font-size: 15px;
        color:var(--secondry);
        line-height: 30px;
        
    }
    
    .wrapper-child-details-container{
    
        padding: 7px 30px;
        /* border-top:2px solid rgba(0, 0, 0, 0.368) ;
        border-bottom:2px solid rgba(0, 0, 0, 0.368) ; */
    
    }
    
    
    .child-details-right-digital-faq{
      
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;
        border-bottom: 1px solid rgba(45, 40, 40, 0.571);
    }
    .Faq-tougle-ans{
    
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.331); */
            padding: 20px 0px;
            font-size: 15px;
          
            color: #757A7B;
            background-color: green !important;
    }
    .lst{
        border-bottom: none;
    }
    /* FAQ plus icon  */
    .FAQ-PLUS{
    
        color:var(--primary);
    }
    .Faq-tougle-QA{
        color:  #04171A;
        font-size: 18px;
        font-weight: 600;
        font-family: Syne;
    }
    @media(max-width:1190px){
    
    
        .inner-digital-mian-faq{
    
            max-width: 1200px;
            max-height: 1120px;
          
            margin: auto;
            background-color: var(--text);
            /* background-color: rgb(244, 250, 250); */
        
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
            /* padding: 50px 100px; */
            gap: 10px;
            /* justify-content: space-between; */
        
        }
    }
    @media(max-width:768px){
    
        .inner-details-left-digital-faq-lift{
            display: flex;
            flex-direction: column;
        
        }
        .details-left-top-digital-faq .head{
    
            font-size: 35px;
            
        }
    
       
        .inner-digital-mian-faq{
    
         grid-template-columns: 1fr;
            padding: 20px 10px;
            gap: 10px;
         
            /* justify-content: space-between; */
        
        }
    }