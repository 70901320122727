.inner-team-details-main-container {
  max-width: 1300px;
  margin: auto;
  padding: 120px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.team-details-left-container {
  display: flex;
  justify-content: center;
}
.team-details-left-container img {
  max-height: 93%;
  width: 100%;

  padding: 0px 30px;
}
.team-details-right-container {
  padding: 0px 30px;
}
.team-details-right-container > p:nth-child(1) {
  font-size: 40px;
  font-family: Syne;
  color: var(--main);
  font-weight: 600;
}
.team-details-right-container > p:nth-child(2) {
  font-size: 18px;
 
  color: var(--secondary);
  padding: 3px 0px 21px;
}
.team-details-right-container > p:nth-child(4) {
  font-size: 16px;

  color: var(--secondary);
  padding: 21px 0px;
  line-height: 25px;
}
.team-details-right-container > p:nth-child(5) {
  font-size: 22px;
  font-family: Syne;
  color: var(--main);
  font-weight: 600;
  line-height: 25px;
  word-spacing: 2px;
}
.team-detail-icons a i {
  padding: 15px;
  border: 2px solid var(--primary);
  margin-right: 10px;
  color: var(--main);
  border-radius: 15px;
}
.team-detail-best-q-list {
  display: flex;
  gap: 20px;
  padding: 30px 0px;
}
.team-detail-best-q-list p {
  display: flex;

  align-items: center;
  padding: 10px 0px;

  color: var(--secondary);
  font-weight: 600;
}
.team-detail-best-q-list p i {
  color: var(--primary);
  margin-right: 15px;
}

.detailsprocessbar-main-container {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.team-detail-progress-main-c {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 0px;
}

.detailsprogress-container {
  width: 80%;
  max-width: 600px;
}

.detailsprogress-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #0d0d0d;
  margin-bottom: 5px;
}

.detailsprogress-bar {
  width: 100%;
  height: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.detailsprogress-fill {
  width: 0%;
  height: 100%;
  background: linear-gradient(to right, #091e1e, #fbbc05);
  border-radius: 5px;
  animation: fillAnimation 2s ease-in-out forwards;
}

.team-details-certification {
  border-top: 1px solid rgba(0, 0, 0, 0.527);

  margin-bottom: 70px;
}
.inner-team-details-certification {
  max-width: 1300px;
  margin: auto;
  padding: 120px 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.inner-team-details-certification > div {
  flex: 1;
}
.certificate-container > div {
  flex: 1;
}
.certificate-details-container > p:nth-child(1) {
  font-size: 36px;
  color: var(--main);
  font-weight: 600;
  font-family: Syne;
}
.certificate-details-container > p:nth-child(2) {
  font-size: 16px;
  color: var(--secondary);

  margin: 19px 0px 0px;
  line-height: 25px;
  max-width: 490px;
}
.certificate-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.certificate-container > div {
  flex: 1;
}
.dottted-b {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1300px;
  margin: auto;
  padding: 0px 60px;
}

.dottted-b-col {
  display: flex;
  align-items: center;
}
.dottted-b-col > span {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--primary);
}
.dottted-b-col > span:hover {
  background: transparent;
  border: 1px solid var(--primary);
}
.dottted-b-col > div {
  border: solid 1px #e4e4e4;
  flex: 1;
}

.experience-details-container {
  max-width: 1300px;
  margin: auto;
  padding: 0px 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
.inner-experience-details-container {
  padding: 20px 0px;
}
.inner-experience-details-container > p:nth-child(1) {
  color: var(--primary);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
 
  letter-spacing: 1px;
}
.inner-experience-details-container > p:nth-child(2) {
  color: var(--main);
  font-weight: 600;
  font-size: 20px;
  font-family: Syne;
  margin: 6px 0px 4px;
}

.inner-experience-details-container > p:nth-child(3) {
  color: var(--secondary);
  font-size: 16px;

}
@keyframes fillAnimation {
  from {
    width: 0%;
  }
  to {
    width: 77%;
  }
}

@media (max-width: 1100px) {
  .team-details-right-container {
    padding: 0px 30px;
  }
  .inner-team-details-certification {
    max-width: 1300px;
    margin: auto;
    padding: 120px 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media (max-width: 1000px) {
  .inner-team-details-main-container {
    margin: auto;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .inner-team-details-certification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  .experience-details-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .inner-team-details-main-container {
    padding: 120px 60px;
  }
  .team-detail-best-q-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .inner-team-details-certification {
    padding: 120px 90px;
  }
  .certificate-container {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    gap: 20px;
  }
  .certificate-container > div {
    flex: 1;
  }
  .certificate-container img {
    width: 100%;
  }
  
.experience-details-container{
    padding: 0px 30px;
    grid-template-columns: 1fr;
}
.dottted-b {
    padding: 0px 0px;
  }
}
@media (max-width: 600px) {
  .inner-team-details-main-container {
    padding: 100px 0px;
  }
  .inner-team-details-certification {
    padding: 120px 30px;
  }
  
}
