

.animation-container1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 1; /* Ensures it's behind other content */
    background-color: transparent;
}


.box1 {
    position: relative;
    width: 100%;
    height: 100%;
}

.box1 div {
    position: absolute;
    background-color: transparent;

    border: 4px solid rgba(245, 218, 12, 0.295);
    opacity: 0.6;
    animation: animate 12s infinite ease-in-out;
}

/* Varying circle sizes */
.circle-1, .circle-5, .circle-9, .circle-13, .circle-17 {
    width: 60px;
    height: 60px;
}
.circle-2, .circle-6, .circle-10, .circle-14, .circle-18 {
    width: 30px;
    height: 30px;
}
.circle-3, .circle-7, .circle-11, .circle-15, .circle-19 {
    width: 40px;
    height: 40px;
}
.circle-4, .circle-8, .circle-12, .circle-16, .circle-20 {
    width: 30px;
    height: 30px;
}

/* Positioning with better distribution */
.circle-1 { top:25%; left: 10%; animation-duration: 14s; }
.circle-2 { top: 35%; left: 5%; animation-duration: 12s; }
.circle-3 { top: 40%; left: 15%; animation-duration: 16s; }
.circle-4 { top: 45%; left: 7%; animation-duration: 18s; }


.circle-10 { top: 20%; left: 60%; animation-duration: 19s; }
.circle-11 { top: 30%; left: 45%; animation-duration: 16s; }
.circle-12 { top: 35%; left: 55%; animation-duration: 14s; }

.circle-5 { top: 25%; left: 89%; animation-duration: 20s; }
.circle-6 { top: 35%; left: 80%; animation-duration: 14s; }
.circle-7 { top: 40%; left: 90%; animation-duration: 17s; }
.circle-8 { top: 45%; left: 89%; animation-duration: 13s; }
.circle-9 { top: 85%; left: 90%; animation-duration: 15s; }


.circle-13 { top: 80%; left: 30%; animation-duration: 20s; }
.circle-14 { top: 10%; left: 70%; animation-duration: 18s; }
.circle-15 { top: 40%; left: 90%; animation-duration: 13s; }
.circle-16 { top: 20%; left: 50%; animation-duration: 15s; }
.circle-17 { top: 85%; left: 60%; animation-duration: 22s; }
.circle-18 { top: 5%; left: 80%; animation-duration: 19s; }
.circle-19 { top: 90%; left: 20%; animation-duration: 14s; }
.circle-20 { top: 70%; left: 85%; animation-duration: 17s; }

@keyframes animate {
    0% {
        transform: scale(0.8) translateY(0) rotate(0deg);
        opacity: 0.5;
    }
    50% {
        transform: scale(1.4) translateY(-70px) rotate(180deg);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(-130px) rotate(360deg);
        opacity: 0.3;
    }
}

@media(max-width:768px) {

    .animation-container{
        display: none;
    }
    
}