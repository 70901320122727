.project-details-main-container{

    background-color: white;
}
.inner-project-details-container{

    max-width: 1300px;
    margin: auto;
    padding: 30px 30px;
}
.project-details-headline{
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    align-items: center;
    margin-top: 50px;
}
.project-details-headline>div:nth-child(1){
    font-size: 40px;
    font-family: Syne;
    color:var(--main);
    font-weight: 600;
    
}
.project-details-headline>div:nth-child(2){
    font-size: 15px;
 
    color:var(--main);
    font-weight: normal;
    background-color: var(--primary);
    padding: 2px;
    border-radius: 2px;
    
}
.project-details-image img{
    width: 100%;
    mix-blend-mode: luminosity;
    max-height: 500px;
    margin-top: 15px;
}
.project-details-text-container{
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 20px;
    padding: 50px 0px;
}
.project-details-desc{
    padding-bottom: 30px;
    font-size: 15px;
  
    color:var(--secondary);
    line-height: 30px;

}
.project-details-middle-head{
 margin-bottom: 32px;

 font-size: 20px;
 font-weight: 600;
 color: var(--primary);

}

.project-details-challenge-container>p:nth-child(1){

font-weight: 600;
font-family: Syne;
color: var(--main);
font-size: 30px;
margin-bottom: 30px;
}

.project-details-challenge-list>p{
 
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;

}
.project-details-challenge-list p i{
  margin-right: 10px;
  color: var(--primary);
}
.project-details-challenge-container>p:nth-child(3){
  
    font-size: 15px;
    color: var(--secondary);
    margin-top: 30px;
    line-height: 30px;
 

}
.projects-details-service-card{
    background-color: var(--text);
    border-top: 5px solid var(--primary);
    padding: 40px  0px 20px 60px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.inner-details-text-service-card>p:nth-child(1){
     font-size: 15px;
     color: var(--secondary);
    
}
.inner-details-text-service-card>p:nth-child(2){
    font-size: 18px;
    color: var(--main);
    font-size: Syne;
    font-weight: 600;  
    margin-bottom: 26px;
    padding: 7px 0px;  
}
.inner-details-text-service-card>a{
    color:black;
    

}
.inner-details-text-service-card a i{
    padding: 15px;
    border: 2px solid var(--primary);
    margin-right: 10px;
    border-radius: 15px;
}
.inner-details-text-service-card a i:hover{
    background-color: var(--main);
    color: var(--primary);

    
}

.multiple-projects-Scroll-heading{
    padding: 80px 0px 20px 0px;


}
.multiple-projects-Scroll-heading p{

    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
}
.multiple-projects-Scroll-heading>p:nth-child(1){

  
    color:var(--secondary);
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    word-spacing: 2px;

}
.multiple-projects-Scroll-heading>p:nth-child(2){

    font-family:    Syne;
    color:var(--main);
    font-size:50px;
    font-weight: 700;
    margin: 5px 0px 0px;
}

.multiple-projects-Scroll-card-container{

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
   
}
.multiple-projects-Scroll-card-container>div{
    background-color: green;
}

/* for responsive and padding with screen wise */

@media(max-width:1200px){
    
    .inner-project-details-container{
    padding: 50px 60px;
}
}
@media(max-width:994px){
    
    .inner-project-details-container{
        padding: 50px 50px;
      
    }
    .project-details-text-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 50px 0px;
    }
    }

    
@media(max-width:768px){
    
    .inner-project-details-container{
        padding: 40px 90px;
      
    }
    .project-details-headline{
     flex-direction: column;
     justify-content: flex-start;
     gap: 15px;
     align-items: start;
    }
    .multiple-projects-Scroll-heading>p:nth-child(2){

        font-family:    Syne;
        color:var(--main);
        font-size:30px;
        font-weight: 600;
        margin: 5px 0px 0px;
    }
    .multiple-projects-Scroll-heading{
        padding: 30px 0px 40px 0px;
    
    
    }
  
    }

    
    @media(max-width:550px){
    
        .inner-project-details-container{
            padding: 30px 15px;
        }
        }

        /* scroll-img-scroll-card */
        
@media(max-width:1200px){

    .multiple-projects-Scroll-card-container{

      
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

    }
}
@media(max-width:768px){

    .multiple-projects-Scroll-card-container{

   
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
    
    }
}