.navbar {
  background-color: var(--main);
  color: #fff;
  /* border-bottom: 1px solid #555555; */
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
  z-index: 2;
  position: relative;
  /* transition: 1.4s; */
}

.navbar-fixed {
  background-color: var(--main) !important;
  position: fixed;
  top: -40px;
  width: 100%;
  z-index: 2;
  transition: 0.8s;
  transform: translateY(40px);
}

.navbar a {
  color: #fff;
  text-decoration: none;
}
.navbar a:hover {
  color: var(--primary);
}

.navbar-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  padding: 30px;
}
.navbar-left-col {
  display: flex;
  align-items: center;
  gap: 80px;
}
.navbar-logo  img {
  display: flex;

  width: 150px;
}
.navbar-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar-menu a{
  min-height: 40px;
  display: flex; 
  align-items: center;
  gap: 5px;
}

.navbar-right-col {
  display: flex;
  align-items: center;
  gap: 15px;
}
.navbar-right-col i {
  font-size: 1.8rem;
  color: var(--primary);
  animation: 1.2s ease-in-out 0s infinite normal none running phone-icon-animate;
}

.navbar-contact {
  display: grid;
  font-size: 0.8rem;
  font-weight: 600;
  color: #bebebe;
}
.navbar-contact a {
  font-size: 1.1rem;
}

.navbar-end {
  display: none;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* small nav :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.navbar-small-responsive {
  transform: translateX(100%);
  z-index: 3;
}

.navbar-small {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background-color: #0008;
  color: #fff;
  z-index: 1;
  font-size: 0.9rem;
  transition: 0.4s;
}

.navbar-small-main {
  background-color: var(--main);
  height: 100%;
  width: 80%;
  padding: 30px 15px;
  overflow: auto;
}

.navbar-small-top {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
}

.navbar-small-top img {
  width: 100px;
}

.navbar-small-menu {
  display: grid;
  padding: 40px 0 20px;
}

.navbar-small-menu > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
  border-top: 1px solid #3f3f3f;
}

.navbar-small-menu > a:last-child {
  border-bottom: 1px solid #3f3f3f;
}

.navbar-small-menu > a > i {
  background-color: var(--primary);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-small-contact {
  display: grid;
  gap: 10px;
  font-size: 0.8rem;
}
.navbar-small-contact > a {
  display: flex;
  align-items: center;
  gap: 5px;
}
.navbar-small-contact > a > i {
  width: 25px;
  height: 25px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
}

.navbar-small-links {
  display: flex;
  gap: 20px;
  padding: 15px 0 0;
  font-size: 1.1rem;
}

.Drop-down {
  position: relative;

  /* padding: 10px;
   */
}
.Drop-down-list {
  position: absolute;
  display: grid;
  background: #fff;
  color: #000;
  margin-top: 10px;

  display: none;
}


.Drop-down-list a {
  padding: 5px 20px 5px 10px;
  color:var(--secondary);
 
  font-size: 15px;
  width: 190px;
}

.Drop-down-list {
  background: #fff;
  position: absolute;
  display: grid;
  margin-top: 0px;

  padding: 20px 20px 20px 20px;
  display: none;
}
.Drop-down-list a:hover{
  color: var(--main);
  background-color: #bebebe;
}
.Drop-down:hover .Drop-down-list {
  display: grid;
}
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

@media only screen and (max-width: 1100px) {
  .navbar-right-col {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-menu {
    display: none;
  }

  .navbar-end {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 0.9rem;
  }

  .navbar-end > i {
    font-size: 1.3rem;
  }

  .navbar-end > a > i {
    color: var(--primary);
    animation: 1.2s ease-in-out 0s infinite normal none running
      phone-icon-animate;

    font-size: 1rem;
  }
  .navbar-main {
    padding: 20px 10px;
  }
  .navbar-logo > img {
    width: 100px;
  }
}

/* for small navbar */
/* 
.Drop a{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.Drop a  i{
  background-color: var(--primary);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.small-Drop-down {


  color:var(--text);

  border-top: 1px solid #3f3f3f;


  padding: 10px 0px;
 
  
}
.small-Drop-down  a{

display: flex;
justify-content: space-between;
align-items: center;

  
}
.small-Drop-down  a i{

  width: 30px;
  height: 30px;
  background-color: var(--primary);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
    
  }
.small-Drop-down-list {
  position: relative;
  display: grid;
  background:var(--main);
  color:var(--text);
  margin-top: 10px;
  width: 100%;
 
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
 

  /* display: none; */
}


.small-Drop-down-list a {
 
  padding: 15px 50px;
  color:var(--text);

  font-size: 15px;
  /* width: 100%; */
  border-top: 1px solid #3f3f3f;


}


.small-Drop-down-list a:hover{
  color: var(--main);
  background-color: #bebebe;
}


.navbar-small-menu a>.fa-arrow-right{
  background-color: transparent !important;
} 
.rotate-icon-service {
  color: var(--text);
  transform: rotate(90deg);
  transition: transform 0.5s ease-in-out;
}
.rotate-icon-company {
  color: var(--text);
  transform: rotate(90deg);
  transition: transform 0.5s ease-in-out;
}
@media(max-width:768px){


  .navbar-logo  img {
    display: flex;
  
    width: 140px;
  }
}

@media(max-width:500px){

  .navbar-logo  img {
    display: flex;
  
    width: 130px;
  }
  .navbar-main{
    padding: 15px 10px;
  }
}