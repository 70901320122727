.main-parent-bussness-success-rate-container{

    background-color: rgba(15, 14, 14, 0.968);


   padding-top: 50px;
   padding-left: 40px ;
   padding-right: 40px;

}

.inner-bussness-success-rate-container{


    max-width: 1200px;
    margin: auto;
    background-color: rgba(19, 7, 7, 0.128);
    overflow: hidden;
    /* height: 200px; */
    /* margin-bottom: 50px; */




}

.child-bussness-success-rate-container{
    display: flex;
    flex-direction: column;
   padding: 70px 0px;

    /* margin-bottom: 50px; */
}
.child-bussness-success-rate-container .row1{

    /* padding: 40px 0px; */
}

.success-tag-container{


    display: flex; 
    padding: 10px 10px;
  
    /* background-color: white; */
    max-width: fit-content;
margin-bottom: 5px;
    



}
.bb{
    border-bottom:1px solid rgba(255, 255, 255, 0.24);

}
.success-tag-container .iconss{
  width: 70px;
  height: 55px;
    display: flex;
    flex-direction: start;
    background-color: black;
    /* color: var(--primary); */
    border-radius: 50%;
    font-size: 50px;
    padding: 15px 15px;

    align-items: center;
    justify-content: center;
    /* color: yellow; */
    color: var(--primary);
/* border: 0.5px solid white; */
    


}
.iconss i{
    font-size: 30px;

}

.success-tag-container .iconss:hover{
    transition: 1s;
    color: var(--main);
    background-color: var(--primary);
}


/* .icon {
    font-size: 50px;
  
    color: aqua;
    margin-bottom: 15px;

} */
/* 
.icon:hover{
    color: var(--primary);

} */
 .textt div i{
 
    font-size: 45px;
    
 }
.success-tag-textt{
    padding: 10px 30px;
    height: 100%;

    /* border-bottom: 1px solid rgba(255, 255, 255, 0.438); */
}
/* .icon-ckecks{

    background-color: rgb(255, 30, 30) !important;
} */
.success-tag-textt .p1{
    font-size: 20px;
    font-weight: 600;
    /* margin-bottom: 10px; */
    color:#ffffff;

    font-family: Syne;

}
.success-tag-textt .p2{

    margin: 30px 0px 0px;
    font-size: 16px;
    color: #627174;
    padding-bottom: 15px;
    width: fit-content;
}
.success-tag-textt .p2 span{
color:  rgba(255, 255, 255, 0.726);
}

.right-success-rate-container{

    color: white;
    margin-bottom: 20px;
}
.inner-right-sucessr-rate-image{

padding: 10px;
display: flex;
flex-direction: row;
}
.inner-right-sucessr-rate-image .textt{
    max-width: 190px;
    padding: 50px 35px;
    height: 300px;
    background-color: var(--primary);
    color: black;

    font-size: 24px;
    font-weight: 600;
    font-family: Syne;
    display: flex;
    flex-direction: column;
    gap: 30px;
}


 .buness-img{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
    width: 100%;
 }
 .buness-img img{
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;

 }

 @media(max-width:768px){

    .main-parent-bussness-success-rate-container{

    
       padding-top: 0px;
       padding-left: 10px ;
       padding-right: 10px;
    
    }


 
 }

@media(min-width:1200px){
    .child-bussness-success-rate-container{
       display: grid;
       grid-template-rows: 1fr;
       grid-template-columns: 2fr 3fr;
       gap: 10px;   
       /* flex-direction: row; */
    }
}


@media(max-width:600px){
    .inner-right-sucessr-rate-image .textt{
        max-width: 150px;
        padding: 30px 25px;
        height: 250px;
        background-color: var(--primary);
        color: black;
    
        font-size: 24px;
        font-weight: 600;
        font-family: Syne;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .buness-img{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 250px;
        width: 100%;
     }
     .child-bussness-success-rate-container{
        padding: 50px 0px;
     }
    
}