.about-main-container {
  background-color: #fff;
}
.about-inner-container {
  max-width: 1300px;
  margin: auto;

  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 90px 30px;
}
.about-left-container {
  display: flex;

  justify-content: center;
}

.about-left-container img {
  /* background-blend-mode: luminosity; */
}
.about-right-container {
  padding: 0px 10px;
}
.about-right-container > p:nth-child(1) {
  display: flex;
  align-items: center;
  
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--secondary);
  text-transform: uppercase;
}
.about-right-container > p:nth-child(2) {
  font-family: Syne;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--main);
  margin: 6px 0px 0px;
}
.about-right-icon-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.about-right-icon-container i {
  padding: 15px 17px;
  border: 2px solid var(--primary);
  background-color: var(--primary);
  border-radius: 50%;
  margin-right: 15px;
}
.about-right-icon-container i:hover {
  transition: 1s;

  color: var(--text);
  background-color: var(--main);
  border: none;
}
.about-right-icon-container > div {
  display: flex;
  align-items: center;
}
.about-right-icon-container p {
  font-size: 17px;
  font-weight: bold;
  color: var(--main);
  font-family: Syne;
}
.about-right-container > p:nth-child(4) {

  font-size: 16px;
  font-weight: 500;

  color: var(--secondary);
  margin: 31px 0px 36px;
  line-height: 30px;
}
.about-discover-more-btn button {
  padding: 16px 50px 17px;
  font-weight: 600;
  color: var(--main);
  background-color: var(--primary);
  font-size: 16px;
  border: none;
  margin-top: 10px;
}

.agency-services-about-container {
  background-color: darkcyan;
  background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/backgrounds/why-choose-three-bg.jpg");

  background-size: cover;
  background-position: center;
}
.main-inner-agency-services-about-opacity {
  background-color: rgba(18, 3, 3, 0.71);
}
.inner-agency-services-about-opacity {
  max-width: 1300px;
  margin: auto;
  padding: 120px 60px;
  color: white;
}

.agency-services-about-details-top {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.agency-services-about-details-top > div {
  padding: 10px 0px;
  flex: 1;
}
.agency-services-about-details-top-text > p:nth-child(1) {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--text);
  text-transform: uppercase;
  line-height: 10px;
  letter-spacing: 2px;
  word-spacing: 2px;

}
.agency-services-about-details-top-text > p:nth-child(2) {
  font-family: Syne;
  font-size: 45px;
  font-weight: 600;
  color: var(--text);
  margin: 8px 0px 0px;
}

.agency-services-about-details-top-text2 {
  font-size: 16px;
  color: var(--secondary);
  font-weight: 600;
  
  line-height: 25px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
}
.agency-services-about-details-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 25px;
}
.agency-services-about-details-card > div {
  flex: 1;
  padding: 10px 10px;
  background: rgba(27, 17, 17, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Applies blur effect */
  -webkit-backdrop-filter: blur(10px);
  border: 0.5px solid rgba(255, 255, 255, 0.215);
}

.agency-services-about-details-card > div {
  padding: 30px 20px;
}
.agency-services-about-details-card i {
  font-size: 55px;
  color: var(--primary);
  margin-bottom: 20px;
}
.agency-services-about-details-card p {
  font-size: 20px;
  font-family: Syne;
  font-weight: 600;
  color: var(--text);
}

/* about-team-success-main-container style */
.about-team-success-main-container {
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  gap: 40px;

 
}
.about-team-success-top-text-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0px;
}
.about-team-success-top-text-container > p:nth-child(1) {
  font-size: 15px;
  color: var(--secondary);

  align-items: center;
  letter-spacing: 2px;
  word-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 50px;
}

.about-team-success-top-text-container > p:nth-child(2) {
  font-size: 50px;
  color: var(--main);
  font-family: Syne;
  margin: 4px 0px 0px;
  font-weight: 600;
}
.about-team-success-team-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  padding: 0px 15px;
}
.about-team-success-team-card > div {
  background-color: white;

  /* background-color: #f7f3f3; */
}
.about-team-success-team-card img {
  width: 100%;
}
/* .about-team-success-team-card img{
    display: flex;
    margin: auto;
    justify-content: center;
    background-position: center;
}
    */
.about-team-card-detailss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-team-card-detailss > p:nth-child(1) {
  font-size: 20px;
  color: var(--main);
  font-weight: 600;
  font-family: Syne;
  margin: 5px 0px;
}

.about-team-card-detailss > p:nth-child(2) {
  font-size: 15px;
  color: var(--secondary);

}

.about-botton-yellow-card {
  background-color: var(--primary);
  margin-top: 50px;
}
.inner-about-botton-yellow-card {
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 60px 40px;
}
.inner-about-botton-yellow-card > div {
  flex: 1;
}
.icons-about-botton-yellow-card {
  display: flex;
  align-items: center;
  gap: 25px;
  border-right: 2px solid rgba(0, 0, 0, 0.32);
}
.icons-about-botton-yellow-card:last-child {
  border: none;
}
.icons-about-botton-yellow-card i {
  font-size: 55px;
  color: var(--main);
  width: 55px;
}

.icons-about-botton-yellow-card div > p:nth-child(1) {
  font-size: 35px;
  color: var(--main);
  font-weight: bold;
}

.icons-about-botton-yellow-card div > p:nth-child(2) {
  font-size: 12px;
  color: var(--main);
  font-weight: 600;
  letter-spacing: 2px;
  word-spacing: 2px;
}

@media (max-width: 1200px) {
  .about-inner-container {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 90px 120px;
    margin: auto;
  }
  .about-left-container img {
    width: 100%;
    max-height: 650px;
  }
  .agency-services-about-details-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 25px;
  }
  .about-team-success-team-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 1000px) {
  .inner-about-botton-yellow-card {
    flex-direction: row;
    flex-direction: column;
    gap: 30;
    padding: 60px 40px;

    align-items: center;
  }
  .icons-about-botton-yellow-card {
    display: flex;

    gap: 25px;
    border: none;
  }
}
@media (max-width: 992px) {
  .agency-services-about-details-top {
    flex-direction: column;
  }
  .inner-agency-services-about-opacity {
    padding: 120px 100px;
  }
}
@media (max-width: 900px) {
  .about-inner-container {
    padding: 90px 90px;
  }
  .about-right-container {
    padding: 0px 0px;
  }
  .inner-agency-services-about-opacity {
    padding: 120px 80px;
  }
  .about-team-success-team-card {
    padding: 0px 30px;
  }
}
@media (max-width: 768px) {
  .about-inner-container {
    padding: 90px 90px;
  }
  .about-right-icon-container {
    flex-direction: column;
  }
  .about-right-container > p:nth-child(2) {
    font-size: 35px;
  }
  .about-right-container {
    padding: 0px 0px;
  }
  .inner-agency-services-about-opacity {
    padding: 120px 80px;
  }
  .agency-services-about-details-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 25px;
  }
  .agency-services-about-details-top-text > p:nth-child(2) {
    font-size: 35px;
  }
  .about-team-success-team-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 0px 30px;
  }
  .about-team-success-top-text-container > p:nth-child(2) {
    font-size: 35px;
  }
}
@media (max-width: 600px) {
  .about-inner-container {
    padding: 90px 15px;
  }
  .about-right-container {
    padding: 0px 0px;
  }
  .inner-agency-services-about-opacity {
    padding: 100px 20px;
  }
  .about-team-success-main-container {
    padding: 90px 15px;
    gap: 40px;
  }
  .about-team-success-team-card {
    padding: 0px 5px;
  }
}
