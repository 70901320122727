.lattest-artical-main-container {
}
.inner-lattest-artical-main-container {
  max-width: 1200px;
  margin: auto;

  display: flex;
  flex-direction: column;
  padding: 40px 10px;
}


::-webkit-scrollbar {
  display: none;
}
.artical-heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 0px;
}
.artical-heading-container .p1 {
  font-size: 15px;

  color: #757a7b;
  font-weight: 600;
  display: flex;
  align-items: center;
  letter-spacing: 1.5px;
  word-spacing: 1px;
}
.artical-heading-container .p2 {
  font-size: 50px;
  font-family: Syne;
  color: #04171a;
  margin: 4px 0px 0px;
  font-weight: 600;
}
.artical-card-image-container {
  display: flex;
  flex-direction: row;
  gap: 22px;
  padding-top: 40px;
  overflow: hidden;
}
.artical-card-image-container > div {
  flex: 1;

  background-size: cover;
  background-color: white;
}
.artical-img-container img {
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.dddd {
  background-color: black;
  display: flex;
  justify-content: flex-end;

  z-index: 1;
  color: white;
  margin-top: -40px;
  margin-left: 0px;
}
.dddd .day {
  padding: 2px 2px;
  background-color: var(--primary);
  padding: 7px;
}
.dddd .month {
  padding: 2px 0px 2px;
  padding: 7px 7px 7px 0px;
  background-color: #04171a;
}
.dddd .year {
  padding: 0px 20px;
}
.artical-profile-text-container {
  padding: 25px 35px 30px;
  /* background-color: white; */
}

.inner-artical-profile-text-container {
  /* background-color: aliceblue; */
}
.artical-profile-img-container {
  display: flex;
  font-size: 13px;

  font-weight: 500;
}

.artical-profile-img-container img {
  border-radius: 50px;
  margin-right: 20px;
}
.inner-artical-profile-text-container .artical-head {
  font-size: 22px;
  color: #04171a;
  font-family: Syne;
  padding: 20px 0px;
  font-weight: 600;
}
.inner-artical-profile-text-container .artical-desc {
  font-size: 15px;

  color: #757a7b;
}
.artical-card-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 15px;
  background-color: #f5f3ee;
  margin-top: 20px;
  font-size: 15px;

  
  font-weight: 600;
  color: #757a7b;
}

/* lets worktoogether section Style */
.Lets-work-together-main-container {
}
.inner-Lets-work-together-main-container {
  max-width: 1500px;
  margin: auto;
  background-color: var(--primary);
  padding: 50px 100px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner-Lets-work-together-main-container .heading {
  font-size: 30px;
  color: #04171a;
  font-family: Syne;
  font-weight: 600;
  padding: 41px, 50px, 41px, 80px;
}


.work-together-contact-btn {
  padding: 16px 50px 17px;
  background-color: var(--text);
  font-weight: 500;
  font-family: Syne;
  border: none;
  color: white; /* Ensuring the text is white initially */
  font-size: 16px;
  position: relative;
  transition: color 0.4s ease-in-out;
  overflow: hidden;
}

.work-together-contact-btn::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main);
  color: white;
  transition: transform 0.4s ease-in-out;
}

.work-together-contact-btn::after {
  content: "Contact Now";
  bottom: 0;
  transform: translateX(0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-together-contact-btn:hover::after {
  transform: translateX(100%);
}

.work-together-contact-btn:hover {
  color: var(--main);
}

@media (max-width: 992px) {
  .artical-card-image-container {
    display: flex;
    flex-direction: column;
    /* gap: 22px;
        padding-top:40px ; */
  }
  .inner-Lets-work-together-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .artical-heading-container .p2 {
    font-size: 35px;
  }
  .inner-Lets-work-together-main-container {
    align-items: center;

    padding: 50px 30px 50px;
    text-align: center;
  }
}
