.new-getin-touch-container{

}
.inner-new-getin-touch-container{
    max-width: 1200px;
    margin: auto;
    padding: 50px 40px;
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 10px;
    /* background-color: antiquewhite; */
}
.inner-new-getin-touch-container >div{
    flex: 1;
}
.inner-new-getin-touch-container>div:nth-child(1){
    /* border: 20px solid var(--main);
    border-radius: 20px; */
    overflow: hidden;
    margin-top: 30px;
}
.get-in-touch-map{
    width: 100%;
   height: 100%;
   
}

.get-in-touch-form-container>p:nth-child(1){
    font-size: 40px;
    font-weight: 700;
    font-family: Syne;
    /* margin-bottom: 20px; */
    color: var(--main);
}
.get-in-touch-form-container>p:nth-child(2){
    font-size: 18px;
    font-weight: 600;
    font-family: Syne;
   padding: 10px 0px;
    color: var(--main);
}
.get-in-touch-form-container{
    padding: 0px 20px;
    /* background-color: antiquewhite; */
}
.inner-get-in-touch-form-container input{
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;

    border: 1px solid  rgba(0, 0, 0, 0.402);
    outline: none;

    font-size: 12px;

}
.get-in-touch-row{
    display: flex;
    gap: 10px;
}
.get-in-touch-row>div{
    flex: 1;
  
}
.get-in-touch-row >div input{
    width: 100%;
}
.get-in-touch-textarea textarea{
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    height: 100px;
  
    font-size: 12px;
    outline: none;

}
.get-in-touch-contact-now{

    width: 100%;
}
.get-in-touch-contact-now button{
    width: 100%;
    background-color: var(--primary);
    border: none;
    padding: 10px;
    font-size: 18px;
    font-family:Syne;
    font-weight: 600;

}
.get-in-touch-select{

    width: 100%;
    padding: 10px;
    margin-bottom: 10px;

    border: 1px solid  rgba(0, 0, 0, 0.402);
    outline: none;
  
    font-size: 12px;

}
.get-in-touch-select select{
    border: none;
    width: 100%;
}

@media(max-width:992px){
    .inner-new-getin-touch-container{
        display: grid;
        grid-template-columns: 3fr 2fr;
     padding: 50px 20px;
    }
  
}

@media(max-width:900px){
  
    .inner-new-getin-touch-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
     padding: 50px 20px;
    }
    
}
@media(max-width:768px){
    .get-in-touch-row{
        display: flex;
       
        gap: 0;
        flex-direction: column;
    }
    .inner-new-getin-touch-container{
        display: grid;
        grid-template-columns: 1fr;
     padding: 50px 20px;
    }
    .get-in-touch-map{
        width: 100%;
       min-height: 400px;
       
    }
    .get-in-touch-form-container>p:nth-child(1){

        margin-top: 15px;
        
    }
}
