.why-us-main-container{

    max-width: 1400px;
    margin: auto;
    padding: 80px 30px 0;
    
 
}
.inner-why-us-home-container{

    /* background-image: url('../../images//hireusebg1.png');
    background-size: cover;
    background-repeat: no-repeat; */
    background-color: rgb(251, 253, 255);
    border: 1px solid var(--primary);
    padding: 20px 20px 0px 20px;
  
  
}
.why-top-container-bg{

    /* padding: 200px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../images//whyusbg2.png");

    width: 100%;
    position: relative;
    padding: 230px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    justify-content: center;
  
    position: relative;
}
/* 
.hire-us{
    position: relative;
    background-color: var(--primary);
}
.hire-us-text{
    width: max-content;
    
    
    bottom: 0px;
    align-items: center;
  padding: 30px 70px;
    font-size: 110px;
    font-family: Syne;
    font-weight: 600;
}
    .hire-us-border{
        background-color: #fff;
        height: 100px;
        border-radius: 0 100px 0 0;
        
    }


.why-you-text{

    background-color: var(--card_bg);
    width: max-content;
    position: absolute;

    top: -20px;
    left: 30%;
    right: 50%;
    align-items: center;
  padding: 10px 30px;
    font-size: 18px;
    font-family: Syne;
    font-weight: 600;
    transform: rotate(-5deg);
    z-index: 1;
}
.why-you-text-botton-border{
 
    width: max-content;
    position: absolute;
   background-color: var(--primary);
   padding: 20px;
bottom: -30px;

} */
 .why-top-container-bg{
    position: relative;
 }
 .hire-us{
    position: absolute;
    bottom: -100px;
    background-color: var(--primary);
 }
 .hire-us-text{

    width: max-content;
    
    
    background-color: var(--primary);
  
    align-items: center;
  padding: 25px 60px;
    font-size: 110px;
    font-family: Syne;
    font-weight: 600;
 }
 .why-you-text{

    background-color: var(--card_bg);
    width: max-content;
    position: absolute;

    top: -20px;
    left: 30%;
    right: 50%;
    align-items: center;
  padding: 10px 30px;
    font-size: 18px;
    font-family: Syne;
    font-weight: 600;
    transform: rotate(-5deg);
    z-index: 1;
 }
 .hire-us-border{
    background-color: #fff;
    height: 100px;
    border-radius: 0 100px 0 0;
    
}
.why-us-bottom-tag-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
   
    margin-top: 100px;

   

}
.why-us-bottom-tag-container>div{
 
    width: 100%;
 
   
    gap: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  
    font-size: 22px;
    font-weight: 600;
    font-family: Syne;
    color: #4e4e4e;
 padding-bottom: 20px;
}
.why-us-bottom-tag-container>div div>:nth-child(1){
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    align-items: center;
    background-color: aliceblue;

}
.why-us-bottom-tag-container>div div>:nth-child(1):hover{
    background-color: var(--primary);
    transition: 1s;

}


.our-patnerslogo-heading2{
   
    margin-top: 20px;
  
    font-size: 20px;
    font-weight: 600;
    font-family: Syne;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;


   
}


.patner-logo-line2{
    flex: 1;
    height: 1px;
    background-color: #ddd;
}

.why-us-discover-more{
    font-size: 14px;
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    white-space: nowrap;
}

@media(max-width:1260px){

    .why-us-bottom-tag-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
       
        margin-top: 100px;
    
       
    
    }
}
@media(max-width:900px){

    .why-us-bottom-tag-container{
        display: grid;
        grid-template-columns: 1fr;
       
        margin-top: 100px;
    
       
    
    }
}
@media(max-width:768px){

    .hire-us-text{

        width: max-content;
        
        
        background-color: var(--primary);
      
        align-items: center;
      padding: 25px 30px;
        font-size: 70px;
        font-family: Syne;
        font-weight: 600;
     }
     .why-you-text{

 
    
        top: -20px;
        left: 20%;
     }
     .why-us-main-container{

    
        padding: 50px 20px 0;
        
     
    }
    .inner-why-us-home-container{

        /* background-image: url('../../images//hireusebg1.png');
        background-size: cover;
        background-repeat: no-repeat; */
        background-color: rgb(251, 253, 255);
        border: 1px solid var(--primary);
        padding: 10px 10px 0px 10px;
      
      
    }
    
.why-top-container-bg{

    /* padding: 200px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../images//whyusbg2.png");

    width: 100%;
    position: relative;
    padding: 120px 0px 0px 0px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    height: 400px;
    justify-content: center;
  
    position: relative;
  
}
}
@media(max-width:600px){

    
.why-us-bottom-tag-container>div{
    font-size: 18px;
}
.why-us-bottom-tag-container{
   
}
.why-us-bottom-tag-container>div div>:nth-child(1){
  
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    align-items: center;
    background-color: aliceblue;

}

}