/*  */
.bussnessTag-container{
    max-width: 1400px;
    margin: auto;
  
}
.bussnessTag-container {
 

    display: flex;
    align-items: center;
   


    font-size: 22px  ;
    font-weight: 600 !important;

     border-left: 0.3px solid rgba(186, 180, 180, 0.502);
    border-right: 0.3px solid rgba(186, 180, 180, 0.502); 

    border-top: 0.3px solid rgba(186, 180, 180, 0.502); 
  /* border: 0.3px solid rgba(186, 180, 180, 0.502); */


  background-color: white;
  height: 80px;

/* }
.bussnessTag-container >div{
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    text-align: center; */
}

.bussnessTag-container>div{
    flex: 1;
}
.hexagon1{

 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text);
    background-color: var(--main);
    height: 80px;
    z-index: 3;
}
.hexagon2{

 
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: var(--text);
    height: 80px;
    z-index: 1;
}
.hexagon3{

 
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--primary);
    /* padding: 25px 0px; */
}

.bussnessTag-container-shape-left{
    content: "";

    background-color: var(--main);
   
   height: 100%;
    align-items: center;
    position: relative;
    border-top: 40px solid  var(--text);
    border-bottom: 40px solid var(--text);
    border-left: 30px solid transparent;
    z-index: -1;

}
.bussnessTag-container-shape-right{
    content: "";
    background-color:var(--text);
    /* height: 50px; */
   
    height: 100%;
    align-items: center;
    position: relative;
    border-top: 40px solid var(--primary);
    border-bottom: 40px solid var(--primary);
    border-left: 30px solid transparent;
    z-index: 1;

}



@media(max-width:590px){
    .hexagon {
        width:100%;
        height: 60px;
        
    }
    
}
@media(max-width:768px){

    .hexagon1{
        font-size: 18px;
    }
    .hexagon2{
        font-size: 18px;
    }
    .hexagon3{
        font-size: 18px;
    }
}

@media(max-width:400px){
    .hexagon1{
        font-size: 16px;
   
    }
    .hexagon2{
        font-size: 16px;
    
    }
    .hexagon3{
        font-size: 16px;
      
    }
}