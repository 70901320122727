.best-project-container{


    background-color: white;
    padding-top: 0px;
   
}

.inner-best-project-container{

    max-width: 1300px;
    margin: auto;
  

    
}
.best-project-top-text-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;
    text-align: center; 
    
   


    /* display:  grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    text-align: center;  */


}
.sm-text-project{
 
    color:var(--secondary);
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
   margin-top: 15px;
    display: flex;
    align-items: center;
    word-spacing: 1px;
    letter-spacing: 1.5px;
    padding: 5px 0px;

}
.headline-project{
    display: flex;
     font-size: 50px;
     font-weight: 600;
     color:var(--main);
     font-family: Syne;
     align-items: center;
     justify-content: center;
    margin: 4px 0px 0px;
}

.best-project-image-container{

    padding: 20px 0px;
    overflow: hidden;
    margin-bottom: 40px;
    /* background-color: red; */


}
.inner-best-project-image-container{

    display: flex;
    flex-direction: row;
    gap: 20px;

}
.inner-best-project-image-container a{
    color: var(--main);
}
.outer-project-card{
    padding: 5px 15px;
}

.inner-best-project-image-container>div{

flex: 1;
    background-color: rgb(251, 245, 245);
     overflow: hidden; 
} 
.project-card{


   width: 100%;
    max-height: 600px;
    
    
}

/* .inner-best-project-container >.shadow-container{
    flex: 1;
} */
/* .best-project-img{


    display: flex;
    justify-content: center;
  
} */
.best-project-img {
 
    justify-content: center;
    position: relative;
   overflow-y: hidden;
   height: 600px;
   width: 100%;
}

/* Image Styling */
.best-project-img img {
 
  
  
 
  
    /* position: absolute; */
    width: 100%;
  height: auto;
    
    object-fit: cover;
    /* transition: filter 0.4s ease-in-out; */
    /* overflow: hidden; */
}

/* Overlay for Hover Effect */
.bet-project-img::after {
    content: "";
    position: absolute;
    bottom: -40px; /* Initially hidden below */
    width: 100%;
    height: 100%; /* Height of the yellow effect */
    background: rgb(228,221,7);
    background: linear-gradient(0deg, rgba(228,221,7,1) 2%, rgba(255, 255, 255, 0.438) 92%);    opacity: 0;
   
   
    /* transition: bottom 0.4s ease-in-out, opacity 0.4s ease-in-out; */
}

/* Hover Effects */
/* .best-project-img:hover img  {
    filter: brightness(0.8); Image dark hoga
} */



/* .best-project-img:hover::after {
    bottom: 0; 
    opacity: 0.6;
} */


/* .project-card:hover .best-project-img::after {
    bottom: 0; 
    opacity: 0.6;
} */





.bggee{

    /* background-color: white; */
    padding: 20px;
    margin-top: -180px;
    position: absolute;
}
.bggee:hover{

    transition: transform 150ms;
    transform: translateY(-10px);
}
.bggee span{
    background-color: var(--primary);
    font-weight: 600;

    /* padding: 10px 20px;
    margin-bottom: 20px; */
    /* padding: 20px 29px 12px 35px; */
    padding-left:30px ;
    padding-right: 60px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-size: 14px;
    clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);

    
}
.tech-text{

    margin-top: 10px;
    padding-left:40px ;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding: 30px 50px 35px 60px; */
    font-size: 22px;
    font-family: Syne;
    background-color: white;
    color: black;
    width: 300px;
    height: 120px;
    display: flex;
    font-weight: 500;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
/* .shadow-container{


    
    flex: 1;
    color: white;
 
    height: 500px;
    z-index: 10;
    position: absolute;
    background-color:black;
} */

@media(max-width:1200px){

    .inner-best-project-image-container{

        display: flex;
        flex-direction: row;
        gap: 20px;
    
    }

}
@media(max-width:800px){
    .inner-best-project-image-container{

        display: flex;
        flex-direction: row;
        gap: 20px;
    
    }

}
@media(max-width:768px){


    .headline-project{
        display: flex;
         font-size: 31px;
         font-weight: 700;
        }
        .inner-best-project-container{

            padding: 0px 10px !important;
        
        }
 
        .best-project-top-text-container{

        
            padding: 20px 15px;
          
        }
     
}

@media(max-width:990px){
    .inner-best-project-container{

        padding: 0px 25px !important;
    }

}


@media(max-width:400px){
    .tech-text{

        margin-top: 10px;
        padding-left:40px ;
        padding-right: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
        /* padding: 30px 50px 35px 60px; */
        font-size: 22px;
        font-family: Syne;
        background-color: white;
        color: black;
        width: 220px;
        height: 120px;
        display: flex;
        font-weight: 500;
        align-items: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
    }
}