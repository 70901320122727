.digitalcard-main-container{

    background-color:rgb(255,255,255);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* margin-top: ; */
    z-index: 1;
    padding: 20px 15px;
    max-width: 1500px;
    margin: -70px auto 0; 

}
.set-top-card-container-opacity{
    opacity: 0;
}
.set-top-card-container-animation{
    animation: fadeInUp 1s;
}



.inner-card-container-opacity{
    /* opacity: 0; */
}
.inner-card-container-animation{
    /* animation:fadeOutRight 1s; */
}



    .set-top-card-container{

    z-index: 1;  
 
    margin-left: 45px;




}
.digitalcard-inner-container{

    /* max-width: 1200px; */
    /* margin: auto; */
    display: flex;
    flex-direction: column;

        /* justify-content: flex-start;
        background-color: red; */

    gap: 10px;
}

.inner-card-of-marketing{
    
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: red; */


    /* flex-wrap: wrap; */
    justify-content: flex-start;
    padding: 20px 20px;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
   
    /* margin-bottom: 20px; */
    
}
.inner-card-of-marketing2{

    /* margin-bottom: 20px; */
  
    margin-bottom: 5px;

}
.inner-card-of-marketing2 a{
    color:var(--main);
    position: relative;
}
.inner-card-of-marketing2 a .plus{
    position: absolute;
    bottom: -20px;
    left: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width:  45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    font-size: 22px;
}
.inner-card-of-marketing>div{

    background-color: var(--card_bg);
    /* background-color: rgba(249, 158, 230, 0.927); */
    /* background-color: #1b0c0c7e; */
    flex: 1;
}


.card-botton-2{

 

    /* border-top: 1px solid black; */

 
}

.card-botton-2 p{


    font-size: 14px;
    padding:  30px  0px;
    /* border-top: 1px solid rgba(0, 0, 0, 0.639); */
}




/* hundread of customer  */
/* customer-cards-main-container */
.customer-cards-main-container {
    background-color: black;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    /* margin-top: 280px; */
}

.shadow-customer-cards-main-container {
    /* height: 100%; */
    /* width: 100%; */
    /* margin-top: -150px; */
    display: flex;

    background-image:url("../../images//digitalcardbgimg.png") ;
    /* background-image: url("../images/trust-one-img-1.png"); */
    background-color: rgb(248, 236, 236);
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat;
    background-blend-mode: normal;
  
   

}
.shaw-2{


    width: 100%;

background: rgb(116,62,85);
background: radial-gradient(circle, rgba(116,62,85,1) 0%, rgba(85,62,73,0.5832457983193278) 0%, rgba(0,4,9,1) 62%);
}



.inner-card-container{


    /* background-color: #1b0c0c7e; */


    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    height: 100%;
    align-items: center;

    padding: 100px 40px;
    
}

.card-conainer-text{

    font-size: 30px;
    color: #ffff;
    font-family: Syne;
    
    
}




.btn-get-free-qurts{
    padding: 16px 50px 17px;
    background-color: var(--text);
    padding: 20px 40px;
    border: none;
    color: black;
    font-weight: 700;
   
    font-size: 16px;
    position: relative;
    transition: color 0.4s ease-in-out;
    overflow: hidden;
  }
  
  .btn-get-free-qurts::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    color:var(--mian);
    transition: transform 0.4s ease-in-out;
  }
  
  .btn-get-free-qurts::after {
    content: "Get Free Quote";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-get-free-qurts:hover::after {
    transform: translateX(100%);
  }
  


















/*Digital card style    */

.inner-card-of-marketing { 
    display: flex;
    /* flex-wrap: wrap; */
   
    padding: 30px 5px;
    justify-content: flex-start;
  
}

.inner-card-of-marketing2 { 
    display: flex;
    /* justify-content: center;
    align-items: center; */
    /* padding: 10px; */
    width: auto;
    
    
    
}
.inner-card-of-marketing2 a{
    text-decoration: none;
    width: 100%;
 


}

.card {
    width: 100%;
    /* height: 250px; */
    /* background-color: #eddfe676;  */
    border-radius: 0px;
    display: flex;
    /* color: var(--main); */
        font-size: 32px;
        font-family: Syne;
    flex-direction: column;

   
    position: relative;
    padding: 50px  40px;
    /* padding-right: 50px;
    padding-top: 50px; */
    gap: 20px;

    /* padding-bottom: 50px; */
}
.inner-card-of-marketing2 a:hover{
    color:var(--text)

}
.inner-card-of-marketing2:hover {
    background-color: var(--main);
   color: var(--text) !important;
}

.inner-card-of-marketing2:hover .plus{
    background-color: var(--primary);
}
.card .icon img{
    
    color:var(--primary);
    height: 120px;
    width: 120px;
    padding: 0px;
    margin-left: -30px;
    /* background-color: black; */
 
}
.card .icon{
    display: flex;
   
    padding: 0px;
    /* background-color: black; */
}

.card .icon i{

    font-size: 60px;
}


.title {
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    /* color: #222; */
}

/* .plus{
  
   
    background-color: white ;

    padding: 15px;
    display: flex;

    justify-content: flex-start;

    cursor: pointer;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    width: max-content;
} */

.pluss1{
   
    background-color: white ;

    padding: 15px;
    display: flex;

    justify-content: flex-start;

    cursor: pointer;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    width: max-content;
    position: relative;
    margin-bottom: 0px ;
    background-color: red;
    /* margin-bottom: 100px; */
    /* position: relative;
    margin-bottom: -60px ; */


}

.card-conainer-text{

    display: flex;
    
    font-size: 30px;
    color: #ffff;
    font-weight: 600;
    font-family: Syne;
    line-height: 36px;
    margin-bottom: 10px;
    
    
}



.card-botton-2 a{
    color: black;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    text-decoration-color: var(--primary);
}

.card-botton-2{

  
    align-items: center;

}
.card-botton-2 p{


    display: flex;
    align-items: center;

    padding:20px 0px;
    margin-top: 10px;
    font-size: 16px ;
 
    color:#757A7B;
}
.card-bottom-2-icon-container{


  
    display: flex;

}
.card-bottom-2-icon-container div{

    height: 25px;
    width: 25px;
   
    padding: 20px;
    border-radius: 50px;
    background-repeat:no-repeat ;
    background-size: cover;
}
.card-profile1{

   background-image: url("../../images//dgcardsm1.jpg");
}

.card-profile2{
    background-color: red !important;
    margin-left: -12px;
    background-image: url("../../images//dgcardsm2.jpg");

}

.card-profile3{
    background-image: url("../../images//dgcardsm3.jpg");
    margin-left: -12px;

    margin-right: 20px;
}

@media(max-width:1000px){
    /* .plus {
   
        bottom: -28px;
  
    } */
}

@media(max-width:900px){

    .inner-card-of-marketing{


        display: flex;
        flex-direction: column;
        gap: 40px;
       
    }
}

@media(max-width:768px){
    .card{
        padding: 40px 40px;
    }
}
@media(max-width:670px){



    .inner-card-container{


        background-color: #1b0c0c7e;
    
    
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        /* height: 100%; */
        align-items: center;
    
        padding: 40px;
        gap:10px
        
    }
    .digitalcard-main-container{

        background-color:rgb(255,255,255);
        /* margin-top: -170px; */
        z-index: 1;
        padding: 20px 15px;
    
    }
    .card-conainer-text{

        display: flex;
        justify-content: center;
       
        align-items: center;
        margin: auto;
        font-size: 24px;
        color: #ffff;
        font-weight: 600;
        font-family: Syne;
        line-height: 36px;
        margin-bottom: 10px;
        text-align: center;
        
        
    }
    .set-top-card-container{


        z-index: 1;  
        /* display: flex; */
        margin-left: 20px;
   
    
    }
}





