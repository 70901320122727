

/* Footer Container */
.footer {
    background: #0c0f11;
    /* padding: 100px 20px; */
    color:white;
    
}


.footer-container {
    padding-top: 92px;
    padding-bottom: 93px;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
     gap: 30px;
    padding: 90px 20px;
    justify-content: space-around;
  
}
.footer-container>div{
    /* flex: 1; */
}

.footer-contact-details{

    margin-top: 20px;
    color: #627174;
   
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: #ffffff;
    font-size: 15px;
    
 
}
.footer-contact-details i{

    color: var(--primary);

    margin-right: 10px;
}

.footer-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
}
.footer-section .footer-title{
    font-size: 20px;
    font-family: Syne;
    color: white;
    font-weight: 600;
    margin-top: 22px;
    
}

.footer-logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
    /* margin-bottom: 15px; */
    margin-top: 20px;
}

.footer-text {
    padding: 15px 0px;
    font-size: 15px;
    line-height: 1.5;
    color: #627174;
    font-weight:noral;
    /* margin-top: 5px; */
 
}

/* Social Icons */
.social-iconss{
   
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  
    
    /* padding-left: 10px; */
   
}
.footer-contact-details2{
    margin-bottom: 2px;
}
.footer-contact-details2 a{
    list-style: none;
    text-decoration: none;
    color:var(--text);

}
.footer-section>p{
    max-width: 270px;
   
}

.social-iconss a{

    list-style: none;
    text-decoration: none;
  color: var(--text);

}
.social-iconss div{
    padding-right: 20px;
    padding-top: 10px;
}
.social-iconss i:hover{
    background-color: white;
    color: var(--primary);;
}

.social-iconss  i {


 
    border: 2px solid var(--primary);
    border-radius: 20px;
    padding: 15px;
    /* border: 2px solid yellow; */
 
 
}

.social-icons img:hover {
    background: var(--primary);
}

/* Footer Links */
.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    /* margin: 5px 0; */
    padding: 5px 0px;
}

.footer-section ul li a {
    text-decoration: none;
    font-size: 15px;

    color: #627174;

  
}

.footer-section ul li a:hover {
    color: var(--primary);
}

/* Contact Info */
.footer-section p {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #627174;
    font-size: 16px;
    padding: 0px;
 
}



/* Newsletter */
.newsletter {
    display: flex;
    border: 1px solid gray;
    border-radius: 5px;
    overflow: hidden;
}

.newsletter input {
    flex: 1;
    padding: 15px;
    border: none;
    background: transparent;
    color: #627174;
}

.newsletter button {
   
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    background-color: none;
    background-color: var(--primary);
}


label {
    font-size: 15px;

    color: #627174;
    margin-top: 5px;
}

.footer-copy-section{


    

    background: #0c0f11;
    
    color: aliceblue;
}
.inner-footer-copy-section{

    max-width: 1200px;
    margin: auto;
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.171);
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.511);
    padding: 30px 0px;
}


@media only screen and (max-width: 600px) {

    .footer-container {
        display: grid;
    }
    
}

