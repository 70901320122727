.inner-project-main-container{

    background-color: white;
    max-width:1300px;
    margin: auto;
    padding: 120px 0px 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

.inner-project-main-container>div{
    background-color: red;

}

/* Hover Effects */
.best-project-img:hover img {
    filter: brightness(0.8); /* Image dark hoga */
}

.best-project-img:hover::after {
    /* bottom: 0; Slide-in animation */
    opacity: 0.6; /* Overlay visible */
}

@media(max-width:1200px){

    .inner-project-main-container{

        background-color: white;
        max-width:1300px;
        margin: auto;
        padding: 120px 0px 120px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    padding: 30px 40px;
    }
}
@media(max-width:768px){

    .inner-project-main-container{

        background-color: white;
        max-width:1300px;
        margin: auto;
        padding: 120px 0px 120px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 30px 30px;
    }
}
