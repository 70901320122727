/* Modal Background */
.modal {
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: transparent;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.329);
    /* background-color: rgba(0, 0, 0, 0.7); */
}

/* Modal Content */
.modal-content {
    display: flex;
    flex-direction: row;
    height: 400px;
    width: 90%;
    max-width: 750px;
    background: #f3eded;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

/* Close Button */
.close-btn {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    background-color: rgb(255, 246, 246) !important;
    width: 35px;
    height: 35px;
    color: black;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

/* Left Section */
.modal-left {
    flex: 1;
    padding: 40px 70px;
    color: var(--main);
    background:var(--primary);
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);
}

.help-tag {
    display: inline-block;
    /* background: #ff6600; */
    color: black;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 15px;
}

h1 {
    font-size: 26px;
    font-weight: bold;
    margin: 15px 0;
}

/* p {
    font-size: 14px;
    margin-bottom: 20px;
} */

/* Email Input and Button */
.email-box {
    display: flex;
    gap: 10px;
}

.email-box input {
    flex: 1;
    padding: 12px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
}

.email-box input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.email-box button {
    /* background: #ff6600; */
    color: black;
    border: none;
    padding: 12px 18px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
}

/* Right Section (Image) */
.modal-right {
    flex: 1;
    position: relative;
}

.modal-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsive Design */
@media (max-width: 668px) {
    .modal-content {
        flex-direction: column;
        height: auto;
    }
    .modal-left {
        clip-path: none;
        padding: 20px;
        text-align: center;
    }
    .modal-right img {
        height: 250px;
    }
}
