.main-pricingtables-container{

}

.inner-pricingtables-container{


    max-width: 1300px;
    margin: auto;
    padding-bottom:60px ;
    position: relative;
   
    
}
.pricingtables-text-container{
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: center;
    align-items: center;
    padding: 112px 0px 90px;
 
  
}
.pricingtables-text-container>div{

    max-width: 690px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.price-sm-head-txt{

    font-size: 15px;
    
      font-weight: 600; 
    display: flex;
    align-items: center;
    line-height: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--secondary);
    letter-spacing: 1.5px;
     word-spacing: 1px;
  
}
.price-main-txt{

    font-size: 50px;
    font-family: Syne;
    color: var(--main);
    font-weight: 600;
    margin: 4px 0px 0px;
}
.price-text-btn{

    display: flex;
    cursor: pointer;
    margin-top: 20px;
    

}
.price-text-btn>div{
    padding: 10px 25px;
  
    color: white;
    font-size: 15px;
   
    
}
.price-text-btn div:nth-child(1){
 color: var(--main);
 background-color: antiquewhite;
}
.price-text-btn div:nth-child(2){
    color: var(--text);
    background-color:var(--main);
   }
.pricingtables-price-card-container{

    display: flex;
    gap: 30px;
    padding: 10px 15px;
    margin-top: 20px;
}
.pricingtables-price-card-container > div{

    flex: 1;
   
    justify-content: center;
    align-items: center;
    background-color: rgb(250, 250, 250);
}
.price-card{
    background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/backgrounds/pricing-page-single-bg.png");
    background-position: top;
    background-repeat: repeat-x;
    padding: 30px 0px 25px 0px;
        display: flex;
        flex-direction: column;
  
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(248, 228, 228);
    background-color: var(--text);
  
  


}
.prices-text-sm{
    padding: 10px 10px;
    margin-bottom: 5px;
    color: var(--main);
    font-size: 18px;


}
.price-card ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.price-card ul li{
    list-style: none;
    color:var(--secondary);
  
    font-weight: 600;
    font-size: 15px;
    align-items: center;
    padding: 10px 0px;
}
.price-card ul li i{
    color: var(--primary);
    margin-right: 10px;

}
.price-card div{
    padding: 25px 30px;
    border-radius: 50%;
    background-color: var(--text);
    font-size: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;

}
.price-card div:hover{
  background-color: var(--primary);
  color: var(--secondary);

    
}
/* .price-card button{
    margin-top: 20px;
    background-color: var(--main);
    color: var(--text);
    padding: 15px 25px;
    border: none;
    font-weight: 600;
  
} */

.price-card button{
    margin-top: 20px;
    background-color: var(--primary);
    color: var(--main);
    padding: 15px 25px;
    border: none;
    font-weight: 600;

    position: relative;
    transition: color 0.4s ease-in-out;
    overflow: hidden;
  }
  
  .price-card button::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main);
    color: white;
    transition: transform 0.4s ease-in-out;
  }
  
  .price-card button::after {
    content: "Apply Now";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .price-card button:hover::after {
    transform: translateX(100%);
  }
  
  .price-card button:hover {
    color: var(--main
    
    
    
    );
  }


















/* -------------------- */
.prices-txt{

    font-size: 34px;
   
    color: var(--main);
    font-weight: 600;
    margin-top: 10px;
 
}
.prices-desc{


    font-size: 16px;
 
    color: var(--secondary);
    padding: 10px 0px;
}

@media(max-width:992px){

    .pricingtables-price-card-container{

        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 10px 120px;
        margin-top: 20px;
    }
  
}
@media(max-width:768px){
    .price-main-txt{

        font-size: 30px;
    line-height: 45px;
    padding: 0px 3px;
    }
  
}
@media(max-width:700px){

    .pricingtables-price-card-container{

        padding: 10px 50px;
     
    }
}