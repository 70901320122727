.Out-team-main-container{



}
.inner-Out-team-container{

    background-color: white;
    max-width:1300px;
    margin: auto;
    padding: 120px 40px 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}



.inner-Out-team-container>div{
    /* background-color: green; */

}
.Out-team-container-card-img img{
 background-image: cover;
 width: 100%;
 height: 100%;
}
.out-tearm-info-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: var(--text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;

    z-index: 1;
    margin-top: -70px;
    position: relative;  
    padding: 30px 30px;  
   
}
.Out-team-container-card  a{
    color: var(--main);
}
.out-tearm-info-card>p:nth-child(1){
    color: var(--main);
    font-size: 24px;
    font-style: Syne;
    font-weight: 600;
}
.out-tearm-info-card>p:nth-child(3){
    display: flex;
    gap: 10px;
    margin-top: 5px;
}
.out-tearm-info-card>p:nth-child(3) i{
    padding: 10px;
    border: 2px solid  var(--primary);
    border-radius: 10px;
}

.out-tearm-info-card p a{
    color: var(--main);

}

@media(max-width:1200px){

    .inner-Out-team-container{

        background-color: white;
        max-width:1300px;
        margin: auto;
        padding: 120px 0px 120px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    padding: 30px 40px;
    }
}
@media(max-width:768px){

    .inner-Out-team-container{

        background-color: white;
        max-width:1300px;
        margin: auto;
        padding: 120px 0px 120px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 30px 30px;
    }
}