.our-patnerslogo-main-container{

    background-color: rgb(250,250,250);
    overflow: hidden;
}
.our-patnerslogo-inner-container{

    max-width: 1300px;
    margin: auto;
    padding: 50px 20px;
    

}
.our-patnerslogo-heading{
   
  
    font-size: 20px;
    font-weight: 600;
    font-family: Syne;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;


   
}


.patner-logo-line{
    flex: 1;
    height: 1px;
    background-color: #ddd;
}

.patner-logo-title {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    white-space: nowrap;
}
.our-patners-logo-data >div{
    padding: 30px  10px;
   
    font-size: 16px;
    justify-content: center;
    text-align: center;
  
width: 100%;


}

.our-patner-logo-container img{
    height: 100%;
    max-width: 100%;
}

.our-patner-logo-container{
    height: 80px;
    padding: 0px 30px;
    filter: grayscale(0.9);
    opacity: 0.5;
}
.our-patner-logo-container:hover{
    cursor: pointer;
    filter: grayscale(0);
    opacity: 1;

}
@media(max-width:768px){
    .our-patnerslogo-inner-container{

    max-width: 1300px;
    margin: auto;
    padding: 50px 20px;
    

}}