.home-page{}

.home-page-nav .navbar{
    background-color: transparent;
    backdrop-filter: blur(20px);
}

.home-page-header{
    margin-top: -120px;
    margin-bottom: -5px;
}

