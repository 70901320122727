.service-marque-main-container {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;


    padding: 0px 0px 210px 0px;
}

.inner-service-marque-main-container {
    display: flex;
    color: var(--main);
    animation: marquee 30s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

.service-marque-main-container-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.service-marque-main-container-content > div {
    padding: 0px 30px;
    font-size: 20px;
    font-family: Syne;
    font-weight: 600;
}
