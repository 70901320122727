.main-services-container{

 background-color: white;
}
.inner-main-services-container{


    max-width: 1400px;
    margin: auto;
   
}
.services-top-text-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 120px 0px 10px 0px;
}
.services-top-text-container>p:nth-child(1){
    align-items: center;
    color:var(--secondary);
    font-size: 15px; text-transform: uppercase;
  
    font-weight: 600;
    margin-bottom: 3px;
    letter-spacing: 1px;
    word-spacing: 1px;
}
.services-top-text-container>p:nth-child(2){
 
text-align: center;
    align-items: center;
    color:var(--main);
    font-size: 50px; 
    font-family: Syne;
    font-weight: 600;
    margin: 4px 0px 0px;
}
.services-digital-card-1-container{


}

.Services-bussnessShow-container{
    margin-top: 50px;
}
.delever-top-quality-services-container{

    max-width: 1300px;
    margin: auto;
 
}
.services-dark-card{

    display: flex;
    gap: 20px;
    padding: 10px 46px;
    margin-top: 20px;
    margin-bottom: 20px;
 
}

.services-dark-card>div{
    flex: 1;
    color: white;
    background-color:var(--main);
    padding: 50px 50px 50px;
    

}
.inner-services-dark-card>div:nth-child(1){

    height: 60px;
    width: 60px;
    background-color:var(--primary);
    border-radius: 50px;
    color: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
}
.inner-services-dark-card>div:nth-child(2){
    margin: 35px 0px 11px;
    font-size: 24px; font-family: Syne;
    color: var(--text);
    font-weight: 600;
}
.inner-services-dark-card>div:nth-child(3){
  
    font-size: 15px; 

    color: var(--secondary);
    font-weight: 400;
    margin-bottom: 25px;
}
.inner-services-dark-card p{
     font-size: 15px;
   
     color: var(--secondary);
     padding: 7px 0px;
}

.inner-services-dark-card p i{
    margin-right: 10px;
    color: var(--primary);
    
}
.inner-services-dark-card >a{
    color: var(--secondary);
}
.services-plus-btn{

    background-color:rgba(0, 0, 0, 0.84);
    padding: 15px 15px;
    margin-top: 10px;
    color: white;
    border: none;
}

@media(max-width:1200px){
    .services-dark-card{

    
        padding: 30px 70px;
      
     
    }
    .services-dark-card>div{
        flex: 1;
        color: white;
        background-color:var(--main);
        padding: 30px 30px 30px;
        
    
    }
  
}

@media(max-width:992px){
    .services-dark-card{

        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 20px 70px;
        margin-top: 20px;
        margin-bottom: 20px;
     
    }

    
    .services-dark-card>div{
        flex: 1;
        color: white;
        background-color:var(--main);
        padding: 50px 50px 50px;
        
    
    }
  
}

@media(max-width:570px){
    .services-dark-card{      
        padding: 20px 20px;

    }
    .services-top-text-container>p:nth-child(2){

        text-align: center;
            font-size: 33px; padding: 0;
            
        }
}