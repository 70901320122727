.BreadCom-main-container{
    background: url("https://pitoonwp.pixydrops.com/wp-content/uploads/2023/05/page-header-bg.jpg");
    background-position: center;
    background-size: cover;
    margin-top: -105px;
}

.Inner-BreadCom-main-container{
    background: rgba(3, 24, 34, 0.753);
    color: #fff;
    padding: 250px 100px 200px;
}

.Text-BreadCom-main-container{
 
    font-size: 15px;
    font-weight: 700;
    /* border-bottom: 2px solid var(--primary); */
    width: max-content;
    padding-bottom: 5px;
}
.Text-BreadCom-main-container a{
    color: var(--text);
    text-decoration: none;
}
.Text-BreadCom-main-container{
    color: var(--primary);
}
.bread-com-header p{

    font-size: 55px;
    font-weight: 600;
    font-family: Syne;
    color: var(--text);
    padding: 15px 0px;

}

@media(max-width:768px){

    .Inner-BreadCom-main-container{

        padding: 200px 50px;
    }

    .bread-com-header p{

        font-size: 35px;
       
    
    }
}