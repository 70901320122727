.alert-main-container{

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    top: 0;
background-color: rgba(0, 0, 0, 0.543);
 z-index: 3;
    position: fixed;
/* background-color: transparent; */

    

}
.inner-alert-main-container{
    background-color: var(--text);

    border: 1px solid var(--primary);
    padding: 30px 15px;
    border-radius: 15px;
    max-width: 800px;
    min-width: auto;
    display: flex;
  margin: auto;
  justify-content: center;
}
.alert-container{

    display: flex;
    gap: 10px;
  width: 100%;
  padding: 0px 20px;


}
.alert-container>div:nth-child(1){

    height: 40px;
    width: 40px;
    background-color:var(--primary);

    font-size: 20px;

    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.alert-container>div:nth-child(2) p:nth-child(1){
    color:var(--main);

    font-size: 25px;
    font-weight: 600;
    font-family: Syne;

}
.alert-container>div:nth-child(2) p:nth-child(2){
    color:var(--secondary);

    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  

}
.alert-container>div:nth-child(2) p:nth-child(3){
  display: flex;
  float: right;
  padding: 10px;
  margin-top: 10px;


}
.alert-container>div:nth-child(2) p:nth-child(3) button{
  padding: 10px 25px;
  background-color: var(--primary);
  border: none;
  border-radius:5px;
  color:var(--main);
  font-weight: 600;
  
  }


  /* --------------  */

