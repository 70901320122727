.Top-agency-wallpaer-image{


    /* background-color:rgb(250,250,250); */
    /* background-color: white; */
    background-color: #f6f4f0;
}

.top-wall-agency--image-container-opacity{
    opacity: 0;
}
.top-wall-agency--image-container-animation{
    animation:zoomIn;
    animation-duration: 1s
}


.inner-Top-agency-wallpaer-image{  
    max-width: 1300px;
    margin: auto;
    padding: 30px 0px;
}
.top-wall-agency--image-container{
    height: 400px;
    background-image: url("../../images//Screenshot\ 2025-02-21\ 173038.png");
   background-size:cover ;
   background-blend-mode: luminosity;
   background-position: center;
background-repeat: no-repeat;

}
.shadow-top-wall-agency--image{
        height: 100%;
        width: cover;
        display: flex;
        justify-content: flex-end;
}

.shadow-top-wall-agency--image div{
    /* background-image: url("../images/video-one-shape-1.png"); */
    background-image: cover;
    width: 310px;
}

.bgggg{
    width: 280px;
    background-color: rgba(19, 19, 11, 0.637);
}
.main-bttom-agency-image{

    /* background-color: rgb(244, 243, 240); */

}

.inner-bottom-agency-image{
    max-width: 1300px;
    margin: auto;
    display: flex;
    padding: 50px 30px 30px 10px;
    flex-direction: row; 
    gap: 20px;
    flex: 1;
 
  
}
.inner-bottom-agency-image>div{
    background-color:white;
    display: flex;
    flex: 1;

}

.Outer-bottom-agency-image-input-container{
   

    background-image: url("https://pitoonwp.pixydrops.com/wp-content/uploads/2023/05/feature-one-bg.png");

    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}
.shadow--bottom-agency-image-input-container{
    background-color: #f6f4f0f3;
}
.inner-bottom-agency-image-input-container{


    max-width: 1300px;
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;


    padding: 50px 0px;
  

}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #000;
    max-width: 600px;
    width: 100%;
    background-color: #fff;


    margin-bottom: 80px;
}

.input-container input {
    flex: 1;
    border: none;
    padding: 12px;
    font-size: 16px;
    color:var(--main);
    outline: none;
    background: transparent;
}

.input-container button {
    background-color: #091e1e;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.input-container button:hover {
    background-color: #0d2a2a;
}

/* agency-text shap css start */

.genency-text-shap-container>div:nth-child(1){
    display: flex;
  
    background: var(--text);
  

    padding: 10px 0px;
  
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* gap: 40px; */
    margin: 0px;
    padding: 0px;
    width: 100%;
}
.genency-text-shap-container{
    position: relative;
    transition: ease-out 2s;
}
.genency-text-shap-container:hover .inner-bottom-agency-image-hover{
 

    display: block;
  
}
.inner-agency-bg11{

    background-image: url("../../images//servicetaghoverimg1.jpg");
    
    background-size: cover;
  
    background-repeat: no-repeat;
}
.inner-agency-bg22{

    background-image: url("../../images//servicetagimghover-img-2.jpg");
    
    background-size: cover;
  
    background-repeat: no-repeat;
}
.inner-agency-bg33{

    background-image: url("../../images//servicetaghoverimg3.jpg");
    
    background-size: cover;
  
    background-repeat: no-repeat;
}
.inner-bottom-agency-image-hover{
    display: flex;
    /* background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/backgrounds/feature-one-hover-img-2.jpg");
    
    background-size: cover;
  
    background-repeat: no-repeat; */
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    
    position: absolute;
    top: 0;
    overflow: hidden;
    
    
    display: none;
    

}
.inner-bottom-agency-image-hover>div{
    
    
    padding: 46px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  align-items: center;
  height: 100%;

  background-color: #0000009e;

}
.inner-bottom-agency-image-hover>div div{
    flex: 1;
    /* width: 50%; */
}

.inner-bottom-agency-image-hover>div div:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        padding: 20px 28px;
        font-size: 24px;
        font-family: Syne;
        color:var(--text);

}
.inner-bottom-agency-image-hover>div div:nth-child(1){
    display: flex;
    justify-content: center;
   
}
.inner-bottom-agency-image-hover>div div:nth-child(1) i{
   background-color: var(--primary);
   
   width: 50px;
   height: 50px;
   border-radius: 50%;
   display: flex;
  
   justify-content: center;
   align-items: center;
   font-size: 25px;
   /* margin-left: -20px; */
}
.hexagon22 {
   display: flex;
 
   width: 70%;  
    background-image: url("../../images/servicetag1.jpg");
    
    background-repeat: no-repeat;
    background-size: cover;
      clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);

    }
  


    .hexagon221 {
        display: flex;
      
        width: 70%;  
         background-image: url("../../images/servicetagimg2.jpg");
         
         background-repeat: no-repeat;
         background-size: cover;
           clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);
     
         }


         .hexagon222 {
            display: flex;
          
            width: 70%;  
             background-image: url("../../images/servicetagimg3.jpg");
             
             background-repeat: no-repeat;
             background-size: cover;
               clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);
         
             }


    .hexagon22-shadow{
      
        display: flex;
        width: 100%;
 
        /* width: 40%; */
         padding: 70px 20px;
             justify-content: center;
    background-color: #0d1515bd !important;


    }
.icon-containers{


  height: 80px;
  width: 80px;
 
  
  display: flex;
   justify-content: center;
  background-color: var(--primary);
  align-items: center;
   text-align: center;
   color: var(--main);
   font-size: 25px;
   border-radius: 50%;

 
}

.icon-containers img{
  
    border-radius: 50%;
    height: 70px;
    width: 70px;
    /* filter: grayscale(1); */
    /* filter: grayscale(100%) contrast(100%); */
    fill: green;
    
  filter: invert(100%) grayscale(100%) ;

}


.icon-containers-hover{
/* 

    height: 60px;
    width: 60px;
   
    
    display: flex;
     justify-content: center;
    background-color: var(--primary);
    background-color: green;
    align-items: center;
     text-align: center;
     color: var(--main);
     font-size: 25px;
     border-radius: 50%; */
  
   
  }


  /* .icon-containers-hover>div{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: var(--primary);
  } */


.icon-containers-hover img{

    /* background-color: var(--primary);  */
    background-color: var(--main);
    border: solid 1px var(--primary);

       
    height: 80px;
    width: 80px;
    border-radius: 50%;
   
    /* fill: green;
    
  filter: invert(100%) grayscale(100%) ; */

  
   
  }






.icon-containers i{
    padding: 10px 12px;
}
.texttt{
 
  
    display: flex;
    flex-direction: row;
    justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        padding: 20px 30px;
        font-size: 24px;
        font-family: Syne;
        color:var(--main);

        
    
    
}

@media (max-width:900px){


    .inner-bottom-agency-image{

        display:flex;
        flex-direction: column;


    }
  

}

@media(max-width:768px){

    .inner-bottom-agency-image{
        padding: 10px 10px;
    }
    .inner-bottom-agency-image-input-container{
        padding: 25px 10px;
    }
    .input-container {
 
    
        margin-bottom: 40px;
    }
}



/* agency-text shap css end */