.main-contactus-container{

    
}
.inner-contactus-container{
 
    max-width: 1300px;
    margin: auto;
    display: flex;
    /* flex-direction:row; */
    /* display: grid;
    grid-template-columns: 1fr 2fr;
    */
   
    gap: 20px;
    padding: 90px 30px  90px;
    /* justify-content: center; */
    /* background-color: red; */
}
.inner-left-contact-container{

    width: 450px;
    padding: 0px 15px;
    /* background-color: var(--text) !important; */
}
.inner-left-contact-container .p1{

    font-size: 15px; 
   
     color: var(--secondary);
    text-transform: uppercase;
    font-weight: 600;
        display: flex;

    align-items: center;
}
.inner-left-contact-container .p2{
    font-size: 50px; 
    font-weight: 600;
    color: var(--main);
    margin: 4px 0px 0px;
    
    font-family: Syne;

}
.inner-left-contact-container .p3{
  
    font-size: 15px;
    color: var(--secondary);
    font-weight: 600;
 padding: 15px 0px;
 line-height: 30px;
}

.contact-icon-container{

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 20px 5px;

}
.contact-icon-container .c-icons{

    background-color: rgb(240, 236, 231);
    border-radius: 50%;
    padding: 20px 25px;
    color: var(--primary);
}
.c-text p{
    padding: 3px 2px;
   
}
.c-text .text-p1{

    color: var(--secondary);
    font-size: 15px;
    font-weight: 600;
}
.c-text .text-p2{
    font-weight: 600;
    font-size: 18px;
    color: var(--main);
}


.right-contact-container{
    flex: 1;
    background-color: #f5f3ee;
    padding: 60px 60px 60px;
  
}
.contact-input-container{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

}
.contact-input-container>div{
    flex: 1;
    background-color: white;
  
}
.contact-input-container input{
    width: 100%;
    outline: none;
    padding: 25px 15px;
    border: none;
    font-size: 15px;
  
    color: var(--secondary);
    font-weight: 500;

}
.contact-input-container select{
    width: 100%;
    outline: none;
    padding: 25px;
    border: none;
    font-size: 15px;
  
    color: var(--secondary);
    font-weight: 500;
    border: none;
    border-radius: 0px;
   

}
.contact-input-container option{
    background-color: var(--primary);
    color: var(--main);
    border: none;
    border-radius: 0px;
    
}
.contact-input-container textarea{
    width: 100%;
    outline: none;
    padding: 25px 15px;
    border: none;
    min-height: 150px;
    font-size: 15px;
  
    color: var(--secondary);
    font-weight: 500;

}

.send-a-message-btnn{
    background-color: var(--primary);
    padding: 20px 40px;
    border: none;
    color: black;
    font-weight: 700;
 
    font-size: 16px;

}

.map-container{

    width: 100%;
    min-height: 600px;
}
.map-container iframe{

    width: 100%;
    min-height: 600px;
 
    border: none;
}
@media(max-width:1198px){
    .contact-input-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    
    }
   
  
}
@media(max-width:968px){
    .inner-contactus-container{
    flex-direction: column;
   
    }
    .inner-contactus-container{
        padding: 100px 50px  100px;
    }
}
@media(max-width:768px){


    .inner-contactus-container{
        padding: 100px 50px  100px;
    }
    .right-contact-container{
        padding: 60px 20px 60px;
      
    }
    .inner-left-contact-container{

        width:auto;
        padding: 0px 5px;
        background-color: var(--text);
    }
    .inner-left-contact-container .p2{
        font-size: 35px; 
      
    }
   
}
@media(max-width:500px){
    .inner-contactus-container{
        padding: 90px 15px  90px;
    }
}
/* .inner-contactus-container>div{
    flex: 1;
} */