.newfooter-background {
  background-color: var(--main);
}
.main-newfooter-container {
  max-width: 1300px;
  margin: auto;
  padding: 20px 0px;
}
.inner-main-newfooter-container {
  color: white;

  display: flex;
  padding: 50px 40px;

}
.inner-main-newfooter-container div>div {

  flex: 1;
}
.inner-main-newfooter-container div>div:nth-child(1) {

    padding-right: 130px;
  }
.inner-main-newfooter-container > div {
  color: white;

  display: flex;
}
.inner-main-newfooter-container > div > div {
  /* flex: 1; */
}

.inner-child-new-footer-conatiner1 {
  /* background-color: white; */
  /* padding-right: 70px; */

  margin-top: 20px;

  margin-bottom: 35px;
}
.inner-child-new-footer-conatiner1 img {
  color: white;
}
.inner-child-new-footer-conatiner1 div:nth-child(2) {
  max-width: 400px;
  margin: 20px 0px 32px;
  line-height: 30px;
}

.inner-child-new-footer-links{
  display: flex;
  gap: 10px;
}

.inner-child-new-footer-links a {
  color: white;
  
  
  
}
.inner-child-new-footer-links a i {
  padding: 15px;
  background-color: rgba(18, 3, 3, 0.9);
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  /* margin-right: 15px; */
}

.inner-child-new-footer-links a i:hover {
  background-color: var(--primary);
  color: var(--main);
  transition: 1s;
}
.inner-child-new-footer-conatiner2 {
  /* padding-right: 100px; */

  margin-top: 10px;

  margin-bottom: 35px;

  color: black;
  /* margin-right: 40px; */

}
.inner-child-new-footer-conatiner2> :nth-child(1) {
  font-size: 24px;
  font-weight: 600;
  font-family: Syne;
  color: var(--text);
}
.inner-child-new-footer-conatiner2 div {
  margin-top: 35px;
  color: white;
}
.inner-child-new-footer-conatiner2 div a {
  color: #797e88;
}
.inner-child-new-footer-conatiner2 > div p {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: normal;
}
.inner-child-new-footer-conatiner2 > div p i {
  list-style: none;
  text-decoration: none;
  padding-right: 10px;
  color: var(--primary);
  transform: rotate(-45deg);
}

.new-footer-number{
    margin-top: 25px;
}
.inner-child-new-footer-conatiner3 {
  
  
    margin-top: 10px;
  
    margin-bottom: 35px;
  
    color: black;
    /* margin-right: 10px; */
  }
  .inner-child-new-footer-conatiner3> :nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    font-family: Syne;
    color: var(--text);
  }
  .inner-child-new-footer-conatiner3 div {
    margin-top: 35px;
    color: white;
  }
  .inner-child-new-footer-conatiner3 div a {
    color: #797e88;
  }
  .inner-child-new-footer-conatiner3 > div p {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    max-width: 180px;
    /* background-color: red; */
  }
  .inner-child-new-footer-conatiner3 > div p i {
    list-style: none;
    text-decoration: none;
    padding-right: 10px;
    color: var(--primary);
    transform: rotate(-45deg);
  }
  

  @media(max-width:1060px){

    .inner-main-newfooter-container{
        color: white;
      
        display: flex;
        padding: 50px 10px;
      }
  }
  @media(max-width:991px){
    .inner-main-newfooter-container>div:nth-child(1){

        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 50px 30px;
    }

       
  }
  @media(max-width:768px){
    .inner-main-newfooter-container {
      color: white;
    
      display: flex;
      padding: 10px 40px;
    
    }
    .inner-main-newfooter-container>div:nth-child(1){

        display: grid;
        grid-template-columns: 1fr;
        padding: 40px 30px;
    }
    .inner-main-newfooter-container div>div:nth-child(1) {

      padding-right: 0px;
    }
    .inner-child-new-footer-conatiner2{
      margin-bottom: 10px;
    }
       
  }
  @media(max-width:600px){
    .inner-main-newfooter-container>div:nth-child(1){

        padding: 20px 20px;
    }
    .main-newfooter-container{
      padding: 0px;
    }

  
       
  }