.faq-main-container{

    background-color: white;
}
.inner-faq-main-container{
    max-width: 1000px;
    margin: auto;

    padding: 40px 20px;



}
.inner-faq-top-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 150px;
}
.inner-faq-top-container>div:nth-child(1){
height: 100px;
width:100px;
background-color: var(--main);
border-radius: 60px;
padding: 30px;
display: flex;
justify-content: center;


}
.inner-faq-top-container>p:nth-child(2){

    font-size: 22px;
    color:var(--main);
    font-weight: 600;

    padding: 20px 0px;
    word-spacing: 2px;
    letter-spacing: 1px;
    
    
    }
.search-input-container{
    background-color: #f5f3ee;
    padding: 10px 20px;
}
.search-input-container{
    display: flex;
    flex-direction: row;
    justify-content: center;

 position: relative;
 width: 100%;
 height: 63px;
}
.search-input-container input{
    flex: 1;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0px 10px;
}
.search-input-container button{
    padding: 0px 20px;
    border: none;
    background-color: transparent;
    font-size: 22px;
    
}
.Faq-bottom-card-container{
  
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
   
}
.inner-faq-bottom-container{
    margin-top: 50px;
}
.Faq-bottom-card-container>div{
    flex: 1;
}
.Faq-bottom-card{
    background-color: var(--primary);
}
.Faq-bottom-card>div{

    padding: 50px 40px;
} 
.Faq-bottom-card div .card-text-faq{
    font-weight: 600;
    color: var(--mian);
    font-size:25px;
    font-family: Syne;
}
.faq-iconn-container{
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 15px;
}
.faq-iconn-container>div:nth-child(1){
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.faq-iconn-container>div:nth-child(2) p{
   
    font-size: 14px;
    font-weight: 600;

}
.faq-iconn-container>div:nth-child(2) p a{
   
    font-size: 15px;
    font-weight: 600;
    color: var(--main);
    text-decoration:none;

}
.faq-iconn-container>div:nth-child(2) p a:hover{
    color: var(--text);
}

/* call */
.Faq-bottom-card-call{
    background-color: var(--main);
}
.Faq-bottom-card-call>div{

    padding: 50px 40px;
} 
.Faq-bottom-card-call div .card-text-faq{
    font-weight: 600;
    color: var(--text);
    font-size:25px;
    font-family: Syne;
}



.faq-iconn-container-call{
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 15px;
}
.faq-iconn-container-call>div:nth-child(1){
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: white;
 
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.faq-iconn-container-call>div:nth-child(2) p{
   
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary);

}
.faq-iconn-container-call>div:nth-child(2) p a{
   
    font-size: 15px;
    font-weight: 600;
    color: var(--text);
    text-decoration:none;

}
.faq-iconn-container-call>div:nth-child(2) p a:hover{
    color: var(--primary);
}
@media(max-width:992px){
    .Faq-bottom-card-container{
  
        padding: 20px 0px;
        display: flex;
        flex-direction: column;

        gap: 20px;
       
    }
}

@media(max-width:968px){
    .inner-faq-top-container{
        padding: 20px 20px;
    }
}

@media(max-width:768px){

    .Faq-bottom-card>div{

        padding: 40px 25px;
    } 
    .Faq-bottom-card div .card-text-faq{
        font-weight: 600;
        color: var(--mian);
        font-size:22px;
        font-family: Syne;
    }
    .Faq-bottom-card-call div .card-text-faq{
        font-weight: 600;
        color: var(--text);
        font-size:22px;
        font-family: Syne;
    }
    .Faq-bottom-card-call>div{

        padding: 40px 25px;
    } 
}