.top-nav-main-container {
  background-color: #04171a;
  color: #999999;
  padding: 8px 20px;
  font-size: 13px;
  z-index: 1;
  position: relative;
}

.top-nav-inner-container {
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
  gap: 0 20px;
}

.top-nav-left {
    display: flex;
    gap: 20px;
}
.top-nav-left>a{
    color: #999999;
    text-decoration: none;
}

.top-nav-left>a:hover{
    color: var(--primary);
}

.top-nav-left i {
  color: var(--primary);
}

.top-nav-right {
    display: flex;
    gap: 40px;
    align-items: center;
}


.top-text {
  display: flex;
  gap: 15px;
  align-items: center;
}

.top-text span {
  font-size: 18px;
}
.top-text a {
  color: #999999;
  text-decoration: none;
}
.top-text a:hover {
  color: var(--primary);
}

.top-icons-group {
  display: flex;
  align-items: center;
  gap: 20px;
}
.top-icons-group a {
  color: #fff;
  font-size: 14px;
}
.top-icons-group a:hover {
  color: var(--primary);
}

@media only screen and (max-width: 1000px) {
    .top-text{
        display: none;
    }
}



@media (max-width: 670px) {
  .top-nav-main-container {
    display: none;
  }
}
