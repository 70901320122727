.inner-blogpage-container{
    background-color: white;
    max-width:1300px;
    margin: auto;
    padding: 120px 40px 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

@media(max-width:1200px){

    .inner-blogpage-container{

        background-color: white;
        max-width:1300px;
        margin: auto;
        padding: 120px 0px 120px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    padding: 30px 40px;
    }
}
@media(max-width:768px){

    .inner-blogpage-container{

        background-color: white;
        max-width:1300px;
        margin: auto;
        padding: 120px 0px 120px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 30px 30px;
    }
}