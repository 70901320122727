.blog-details-main-container {
}
.inner-blog-details-container {
  max-width: 1300px;
  margin: auto;
  padding: 60px 60px;

  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 20px;
}

.inner-blog-details-left-container {
}
.blog-details-all-text-container {
}
.blog-details-all-text-container > p:nth-child(1) {
  font-size: 30px;
  font-weight: 600;
  color: var(--main);
  margin: 1px 0px 11px;
  margin-top: 15px;
  font-family: Syne;
  line-height: 40px;
}
.blog-details-all-text-container > p:nth-child(2) {
  font-size: 15px;

  color: var(--secondary);

  line-height: 30px;
}
.blog-details-all-text-container > p:nth-child(3) {
  font-size: 15px;

  color: var(--secondary);

  line-height: 30px;
  /* margin-top: 25px;
  margin-bottom: 35px; */
}
.blog-details-bottom-left {
  border-top: 1px solid rgba(0, 0, 0, 0.329);
}
.blog-details-bottom-child-1 {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  align-items: center;
}
.blog-details-bottom-child-1 div span {
  font-size: 22px;
  color: var(--mian);
  font-family: Syne;
  font-weight: 600;
  margin-right: 20px;
}
.blog-details-bottom-child-1 div button {
  font-size: 15px;
  color: var(--secondary);
  background-color: var(--card_bg);
  border: none;
  padding: 10px 20px;
  margin-right: 20px;
}

.blog-details-bottom-child-1 div a {
  color: var(--main);
}
.blog-details-bottom-child-1 div i {
  padding: 12px;
  border: 2px solid var(--primary);
  border-radius: 15px;
  margin-right: 10px;
}
.blog-details-tag-card-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 0px;





  display: none;
}
.blog-details-tag-card-container > div {
  flex: 1;
}
.blog-details-tag-card {
  background-color: var(--card_bg);
  display: flex;
  padding: 40px 40px;
  gap: 15px;
  align-items: center;
}
.blog-details-tag-card div > p:nth-child(1) {
  font-weight: 500;
  font-size: 13px;

}
.blog-details-tag-card div > p:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  font-family: Syne;
  margin-top: 10px;
}
.blog-details-tag-card div > p:nth-child(1) i {
  color: var(--primary);
}
.blog-commnet-profile {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 20px;
  padding: 0px 20px;

  display: none;
}

.commentt-text {
  font-size: 30px;
  font-family: Syne;
  color: var(--main);
  font-weight: 600;
  padding: 10px 0px;
  margin-top: 10px;
  display: none;
}
.blog-commnet-profile {
  padding: 20px 0px 40px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.146);


  display: none;
}
.blog-commnet-profile div img {
  border-radius: 50%;
 
}
.blog-commnet-profile div > p:nth-child(1) {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-family: Syne;
  color: var(--main);
  margin: 0px 0px 22px;
  font-weight: 600;
  align-items: center;
}
.blog-commnet-profile div > p:nth-child(1) button {
  border: none;
  background-color: var(--card_bg);
  font-size: 15px;
  color: var(--secondary);
  padding: 10px 20px;
}

.blog-commnet-profile div > p:nth-child(2) {
  font-size: 15px;

  color: var(--secondary);
  line-height: 30px;
}
.log-details-comment-container {
  border-top: 1px solid rgba(0, 0, 0, 0.128);
  padding: 30px 0px;



  display: none;
}
.log-details-comment-container p {
  font-size: 30px;
  font-family: Syne;
  color: var(--main);
  font-weight: 600;
  padding: 10px 0px;
  margin: 0px 0px 35px;
}
.blog-details-input-box {
  display: flex;
  gap: 20px;
  padding: 15px 0px;
}
.blog-details-input-box > div {
  background-color: var(--card_bg);
  flex: 1;
}
.blog-details-input-box div input {
  width: 100%;
  padding: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  
}
.log-details-comment-container textarea {
  border: none;
  background-color: var(--card_bg);
  width: 100%;
  padding: 30px 20px;
  min-height: 200px;
  outline: none;
 
}

.blog-details-submit-commnet-btn {
  background-color: var(--primary);
  color: var(--main);

  font-weight: 600;
  font-size: 18px;
  padding: 17px 30px;
  border: none;
  margin-top: 10px;
}

.bolog-detail-right-top-input {
  background-color: var(--card_bg);
  padding: 30px 25px;
  display: none;
}
.bolog-detail-right-top-input div {
  background-color: white;
  width: 100%;
  display: flex;
}
.bolog-detail-right-top-input div input {
  width: 100%;
  padding: 17px;
  border: none;
}
.bolog-detail-right-top-input div button {
  padding: 10px;
  background-color: white;
  border: none;
  font-size: 22px;
  outline: none;
}
.blog-details-lattest-project {
  background-color: var(--card_bg);
  padding: 30px 30px;
  margin-top: 0px;
}
.blog-details-lattest-project p {
  font-weight: 600;
  font-size: 20px;
}
.inner-blog-details-lattest-project {
  padding: 0px 10px;
}
.inner-blog-details-lattest-card {
  display: flex;
  gap: 20px;
  padding: 10px 10px;
  margin-top: 7px;
  margin-bottom: 10px;
}
.inner-blog-details-lattest-card:hover {
  background-color: var(--text);
}
.inner-blog-details-lattest-card img {
  border-radius: 50%;
  display: none;
}
.inner-blog-details-lattest-card div p:nth-child(1) {
  font-size: 15px;
  font-weight: 600;
}
.inner-blog-details-lattest-card div p i {
  color: var(--primary);
  margin-right: 5px;
}

.inner-blog-details-lattest-card div p:nth-child(2) a {
  font-size: 15px;
  text-decoration: none;
  color: var(--main);
  font-family: Syne;
  font-weight: 600;
  line-height: 5px !important;
}

.blog-details-category-container {
  background-color: var(--card_bg);
  padding: 50px 30px;
  margin-top: 30px;
}
.blog-details-category-container > p:nth-child(1) {
  font-weight: 600;
  font-family: Syne;
  font-size: 22px;
  padding: 10px 0px;
}
.blog-details-category-container div {
}
.blog-details-category-container div p {
  padding: 10px 15px;

  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.158);
}

.blog-details-category-container div p:hover {
  padding: 10px 15px;
  background-color: white;
  font-weight: 600;
}
.blog-details-category-container div p a {
  text-decoration: none;
  color: var(--secondary);
}
.blog-details-category-container div p i {
  margin-right: 10px;
}

.blog-details-project-slider{
    margin-top: 30px;
    background-color: var(--card_bg);
    padding: 50px 50px;
    display: none;
}
.blog-details-project-slider p{
    font-weight: 600;
    font-family: Syne;
    color: var(--main);
    font-size: 22px;
    margin: 0px 0px 24px;
}
.blog-details-tag-container{
  margin-top: 30px;
  background-color: var(--card_bg);
  padding: 50px 50px;
}
.blog-details-tag-container div button{
  padding: 15px 20px;
  background-color: white;
  color: var(--secondary);
  font-size: 12px;
  font-weight: 500;
  margin-right: 7px;
  margin-bottom: 10px;
  border: none;
}
.blog-details-tag-container div button:hover{
  background-color: var(--primary);
  color: var(--main);
  transition: 1s;
}
.blog-details-tag-container p{
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  font-family: Syne;
}


.blog-details-comments-container{
  margin-top: 30px;
  background-color: var(--card_bg);
  padding: 40px 50px;
}

.blog-details-comment-icon{
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
}

.blog-details-comments-container p{
  color: var(--main);
  font-weight: 600;
  font-family: Syne;
  font-size: 22px;
  padding: 15px 0px;
}
.blog-details-comment-icon >div:nth-child(1){

height: 50px;
width: 60px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
  background-color: var(--text);
}

.blog-details-comment-icon >div:nth-child(1):hover{

    background-color: var(--primary);
    transition: 1s;
  }

  .blog-details-comment-icon >div:nth-child(2){

  color: var(--secondary);

  font-size: 16px;


    }






    /* Update css in bLog one pages (Bussness Blog Start) */


    .blog-deatils-top-images img{
      /* max-height: 400px; */

    }
     .blogs-sub-heading-container{

    
   
     }
     .blogs-sub-heading-container>p:nth-child(1){
      font-size: 24px;
      font-family: Syne;
      color: var(--main);
      font-weight: 600;
      padding: 10px 0px;
     }
     .blogs-sub-heading-container>p:nth-child(2){
      font-size: 15px;
      color: var(--secondary);
     
      line-height: 30px;
      padding: 0px 20px;
     }

     .Blog-paragram-sub-pont-container{
      padding: 20px 0px 20px 30px;
    
    }
   .Blog-paragram-sub-pont-container li{
    font-size: 15px;
    color: var(--secondary);
  
    line-height: 30px;
   }
    
    /* Update css in blog one pages (Bussness Blog End) */



    /* update css fofr add blog page  start*/
    .sub-point-of-p-tag-blog-details{

      font-size: 18px !important;
      font-weight: 500;
      color: var(--main) !important;
      padding: 10px 0px;
    }

        /* update css fofr add blog page  end*/




@media(max-width:992px){
 
  .inner-blog-details-container {

    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media(max-width:768px){


  .inner-blog-details-container {

    padding: 60px 80px;
  

  }
  .blog-commnet-profile>div:nth-child(1){
  
    width: min-content;
   
   
  }

  .blog-commnet-profile div img {
    border-radius: 50%;
    height: 40%;
 
   
  }
  .blog-details-tag-card-container {
    display: flex;
    flex-direction: column;
    
    gap: 10px;
    padding: 10px 0px;
  }

  .blog-details-bottom-child-1 {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    align-items: center;
    gap: 20px;
  }
  .blog-details-input-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0px;
  }
  .blog-commnet-profile {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    padding: 0px 0px;
  }
  

}
@media(max-width:600px){
 
  .inner-blog-details-container {

    padding: 60px 15px;
  

  }
}