.show-project-data-bg{

    background-image: url(../../media/images/background/projectdata-bg.png);
    /* background-image: url("https://pitoonwp.pixydrops.com/wp-content/uploads/2023/05/counter-one-bg.png"); */
  
    left: 0;
    right: 0;
 
   

  
    background-repeat: no-repeat;
    background-size: cover;
  
}
.show-project-data-container{
    /* background-color: rgba(241, 241, 240, 0.941); */
background-color:#f5f3eef8;
height: 100%;
 

}
.card-show-project-data {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    /* padding: 90px 40px; */
    padding: 130px 40px;
    /* padding: 60px 40px 300px; */
    align-items: center;



}
.card-show-project-data>div{

    flex: 1;
}
/* .inner-card-show-project-data>div:nth-child(1):nth-last-child(2) :nth-child(3){
    border-right:1px solid black
} */
.inner-card-show-project-data1{
 
    border-right: 2px solid rgb(234, 229, 222);

    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    padding: 10px;
    
    
}
/* .inner-card-show-project-data1 div:nth-child(1){
    height: 400px;
    width: 100px;
    background-color: red;
} */

.inner-card-show-project-data1 img{
    border-radius: 50%;
    background-color: white;
    padding: 10px;
    height: 120px;
   
}
.inner-card-show-project-data1 img:hover{

    background-color: var(--main);
  
    transition: transform 0.3s ease-in-out;
   

   
}
.inner-card-show-project-data1 img:hover {
    transform: scale(1.1);
    background-color: main;
}




.inner-card-show-project-data1 {
    /* position: relative; */
  
}

/* .inner-card-show-project-data1 img {
    position: relative;
    border-radius: 50%;
    background-color: white;
    padding: 10px;
    height: 120px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
} */
/* 

.inner-card-show-project-data1::before {
    content: "";


    width: 0;
    height: 0;
    background-color: var(--main);
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out, opacity 0.3s ease-in-out;
    transform: translate(-0%, -0%);
    border-radius: 50%;
    opacity: 0;
    z-index: 1; 
}

.inner-card-show-project-data1:hover::before {
    width: 120px; 
    height: 120px;
    opacity: 1;
} */



.inner-card-show-project-data1:last-child{
 
 border: none;
}

.inner-card-show-project-data1>div:nth-child(1){


    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: beige;
    padding: 20px;
    border-radius: 50%;
    font-size: 40px;
    color:var(--primary);
}
.inner-card-show-project-data1>div:nth-child(1):hover{
    background-color: var(--main);
    color: var(--primary);
    transition: .48s;


  
}
.inner-card-show-project-data1>div:nth-child(2){


    text-align: center;
}
.inner-card-show-project-data1>div:nth-child(2) p:nth-child(1){


   font-size: 55px;
   font-weight: 600;

   margin-top: 18px;
}
.inner-card-show-project-data1>div:nth-child(2) p:nth-child(2){


    font-size: 16px;

    color: var(--secondary);
 }

 @media(max-width:768px){

    .card-show-project-data {

        display: grid;
        grid-template-columns: 1fr 1fr;
/*     
        display: flex;
        flex-direction: column; */
        padding: 60px 30px;
        /* padding: 40px 30px 250px 30px; */
        align-items: center;
    
    
    
    }

    .inner-card-show-project-data1{
        border: none;
        margin-bottom: 10px;
    }
    .inner-card-show-project-data1>div:nth-child(1){
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
       
        /* background-color: beige; */
        padding: 20px;
        border-radius: 50%;
        font-size: 24px;
        color:var(--primary);
    }
    .inner-card-show-project-data1>div:nth-child(2) p:nth-child(1){


        font-size: 35px;
   
     }
     .inner-card-show-project-data1 img{
        height: 90px;
       
     }
   
 }