.hhhhhhh {
  display: flex;

  width: 100%;

  overflow: hidden;

  margin-top: -110px;
}

.slider-effect-bgimg-container {
  /* background: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/backgrounds/slider-1-3.jpg"); */
  background: url("../../images/slider-2-2.jpg");
  background-position: center;
  background-size: cover;
  background-color: red;
  width: 100%;
  overflow: hidden;
  align-items: center;
}
.slider-effect-bg-shaow-container {
  background-color: rgba(3, 24, 34, 0.806);

  /* background-repeat: no-repeat;
    background-position: right; */

  display: flex;
  height: 100%;

  align-items: center;
}

.slick-slide{
  border: none !important;
}
.slick-slide>div{
  display: flex;
}

.slider-effect-img-right-container {
  /* background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/shapes/main-slider-two-shape-2.png");
   
    background-repeat: no-repeat; */

  /* background-position: right;
    display: flex;
    align-items: center;
    justify-items: center;
    height: 100%; */

  /* padding: 250px 250px; */

  width: 100%;
  position: relative;
}

.slider-top-left-image {
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 3s ease-in-out infinite;
}
.slider-top-left-image {
  height: 120%;
  display: flex;
  align-items: center;
  opacity: 0.4;
}
.slider-top-left-image img {
  max-height: 600px;
  opacity: 0.5;
}
.slider-top-right-image {
  max-height: 500px;
  background-color: rgb(190, 42, 42) !important;

  /* overflow: hidden; */
}

.slider-top-right-image .image-right-top {
  max-height: 500px;
  position: absolute;
  top: 0;
  right: 0;
  animation: bounce 3s ease-in-out infinite;
}

.slider-top-right-image .image-right-bootom {
  position: absolute;
  top: 38%;
  right: 0;
  animation: bounce 2.2s ease-in-out infinite;
  max-height: 400px;
}

/* .slider-top-right-image{
    background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/shapes/main-slider-two-shape-2.png");
   
    background-repeat: no-repeat;
 
   

} */
/* .slider-botton-right-image{
    background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/shapes/main-slider-two-shape-3.png");

} */

@keyframes bounce {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-20px);
  }
}

/* .slider-effect-img-left-container{
    background-image: url("https://pitoon.pixcelsthemes.com/main-html/assets/images/shapes/main-slider-two-shape-4.png");
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: center;
} */

.slider-effect-textcontainer {
  max-width: 1200px;
  width: 100%;
  padding: 250px 20px 50px;
  margin: auto;
  /* padding: 200px 100px 150px 200px; */
}

.slider-top-text-content {
  max-width: 1200px;
  margin: auto;
  padding: 250px 20px 150px;
}
.slider-top-text-content-heading-1 {
  color: #fff;
  font-size: 100px;
  font-family: Syne;
  line-height: 1;
  font-weight: 600;
  margin: 10px 0 20px;
 

  /* animation:fadeInDown 5s infinite  ; */

}

.slider-effct-animation-1{
  animation: fadeInDownCustom 1s;
}

.circle-text-container{
  animation: fadeInUpCustom 1s ;
}

/* .animated-text {
 
} */

.slider-top-text-content-heading-1 span {
  position: relative;
  background-color: #0009;
  
  
  color: var(--primary);
  border-radius: 40px;
  padding: 0 20px;
  
  /* line-height: .3; */
  height: 10px !important;
  z-index: 1;
}
.active{
  background-color: red !important;
}
.slider-top-text-content-heading-1 span img {
  position: absolute;
  top: 0%;
  right: -20%;
  animation: moveImg 4s linear infinite; 
  z-index: 0;
}
.slider-top-text-content-heading-0 {
  color: white;
  color: var(--primary);
  font-family: Syne;
  font-weight: 600;
  font-size: 20px;
  /* animation: fadeInDownCustom 5s infinite; */
}

.homeeffect-image-circle-image {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.circle-container text {
  margin-bottom: 10px;
  padding: 20px;
  background-color: red;
  color: green;
}
.circle-text-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.circle-text-container svg {
  width: 100%;
  height: 100%;
  animation: rotateText 20s linear infinite;
}

.circle-text {
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  fill: white; /* Text color */
  word-spacing: 8px;
}

.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  font-size: 40px;
  color: gold;
  padding: 20px;
}
.circle-text-container a:hover .center-icon {
  cursor: pointer;
  transition: 0.8s;
  transform: translate(-50%, -50%) rotate(0deg);
}

@keyframes rotateText {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveImg {
  0% {
    transform: translateX(-30px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(-30px);
  }
}

@media (max-width: 768px) {
  .slider-top-right-image {
    display: none;
  }
  .slider-top-text-content-heading-1 {
    font-size: 54px;
    line-height: 1.2;
  }

  .slider-top-text-content-heading-1 span {
    margin-top: 30px;
    background-color: transparent;
    padding: 0;
  }
  .slider-top-text-content-heading-1 img {
    position: absolute;
    top: 66%;
    bottom: 20%;
    max-height: 30px;
    padding-left: 20px;
    opacity: 0.6;
  }

  .slider-top-left-image img {
    /* max-height: 600px;
  opacity: .5; */
    /* max-width: 400px; */
  }

  .slider-top-text-content {
    padding: 220px 20px 110px;
  }
}


@keyframes fadeInDownCustom {
  
  0% {
    opacity: 0;
    transform: translateY(-222px);

  }
  
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: 1s;
  }
}

@keyframes fadeInUpCustom {
  
  0% {
    opacity: 0;
    transform: translateY(222px);

  }
  
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: 1s;
  }
}



