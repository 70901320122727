

@keyframes phone-icon-animate {
    0%,
    100% {
        transform: rotate(0)scale(1)skew(1deg);
    }
    10% {
        transform: rotate(-25deg)scale(1)skew(1deg);
    }
    20%,
    40% {
        transform: rotate(25deg)scale(1)skew(1deg);
    }
    30% {
        transform: rotate(-25deg)scale(1)skew(1deg);
    }
    50% {
        transform: rotate(0)scale(1)skew(1deg);
    }
}






@keyframes blink {
    0%,
    10% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    90%,
    100% {
      opacity: 0;
    }
  }
  


@keyframes shake {
  0% {
    transform: translate(0) rotate(0deg);
  }
  5% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-3px) rotate(1deg);
  }
  15% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  20% {
    transform: translate(1px, -2px) rotate(1deg);
  }
  25% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  30% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  35% {
    transform: translate(3px, -2px) rotate(-1deg);
  }
  40% {
    transform: translate(-1px, 3px) rotate(1deg);
  }
  45% {
    transform: translate(2px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  55% {
    transform: translate(-3px, 1px) rotate(-1deg);
  }
  60% {
    transform: translate(1px, -2px) rotate(0deg);
  }
  70% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  80% {
    transform: translate(3px, -1px) rotate(0deg);
  }
  90% {
    transform: translate(0) rotate(0deg);
  }
  100% {
    transform: translate(0) rotate(0deg);
  }
}




/* .btn-get-free-qurts{


  background-color: var(--primary);
 
     padding: 20px 40px;
     border: none;
     color: black;
     font-weight: 700;
     font-family: Manrope;
     font-size: 16px;
 
 
 } */
 



/* 
.btn-get-free-qurt{
    padding: 16px 50px 17px;
    background-color: var(--text);
    font-weight: 500;
    font-family: Syne;
    border: none;
    color: white; 
    font-size: 16px;
    position: relative;
    transition: color 0.4s ease-in-out;
    overflow: hidden;
  }
  
  .btn-get-free-qurt::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main);
    color: white;
    transition: transform 0.4s ease-in-out;
  }
  
  .btn-get-free-qurt::after {
    content: "Descover More";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-get-free-qurt:hover::after {
    transform: translateX(100%);
  }
  
  .btn-get-free-qurt:hover {
    color: var(--main);
  }    */

