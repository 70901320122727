.best-main-agency-card{


    background-color: white;
    /* background-color:rgb(250,250,250) ; */
/* 
    align-items: center; */

}
/* .agency-right-side-text-container-opacity{
    opacity: 0;
}
.agency-right-side-text-container-animation{
    animation: fadeInUp 1s;
} */


.inner-best-agency{



    /* width: 100%; */

    max-width: 1200px;

    /* padding: 0px 80px; */
    margin: auto;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 4fr 3fr;
  padding: 50px 0px;
    gap: 10px;
    /* background-color: green; */
  

}




.agency-client-img{


    /* background-color: red; */


    padding:15px;

   


    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    /* background-color: red; */
  
 
  
    

}
.agency-client-img img{
    /* min-height: 600px;
    max-height: auto; */

 
    width: 100%;
/*    
    background-size: cover; */
    background-image: none;
    overflow: hidden;
    justify-content: center;
}

.agency-right-side-text-container{

    background-color:white;
    padding:20px 20px;


    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.agency-right-text{


}

.agency-right-text .p1{


    display: flex;
    font-size: 15px;
color: #757A7B;
font-weight: 600;
padding: 10px;


text-transform: uppercase;
align-items: center;




}

.agency-right-text .p4{

    color: #757A7B;
 
    line-height: 30px;
    font-size: 15px;
}

 .fa-chevron-left{

    font-size: 8px;
    color:var(--primary);
    line-height: 10px;
    font-weight:900;
    align-items: center;
    text-align: center;
    padding: 3px;
    
  
}
.fa-chevron-right{

    font-size: 8px;
    color:var(--primary);
    line-height: 10px;
    font-weight:900;
    align-items: center;
    text-align: center;
    padding: 3px;
    
  
}
.agency-right-text .p2{
font-size: 50px;
font-family: Syne;
color: #04171A;
font-weight: 600;
/* line-height: 60px; */
margin: 4px 0px 0px;
}
.agency-right-text .p3{

    font-size: 20px;
    font-family: Syne;
    color:#F9BE17;
    font-weight: 600;
    line-height: 30px;

margin-top: 10px;
margin-bottom: 5px;
}
.agency-right-text .p4{

    font-size: 15px;

    margin: 11px 0px 22px;
    word-spacing: 2px;
    /* letter-spacing: 1.1px; */

}

.agency-right-middle-text{


 

    display: flex;
    flex-direction: row;
  
    justify-content: flex-start;
    gap: 20px;
    padding: 10px 20px 40px  0px;
    /* flex-wrap: wrap; */
    
}
.right-text-col1  div{


    color: #04171A;
  

    font-size: 20px;
    font-family: Syne;
    margin-bottom: 3px;
    font-weight: 600;

   
    /* padding: 0px 0px 0px 10px; */
}

.right-text-col1  div i{
   color: var(--primary);
   transform: rotateX(25deg) !important;
}

.right-text-col1 p{

    font-size: 15px;
    color: #757A7B;
  
    font-weight: normal;
    line-height: 25px;
   

    margin: 10px 0px 0px;
}
.agency-right-desicover-more{



    background-color:var(--main);
    padding: 20px 43px;
    color: var(--text);
  
    border:none;
    margin-top: 30px;
    font-size: 21px;
    font-weight: 600;
    position: relative;
    transition: color 0.4s ease-in-out;
    overflow: hidden;
}





.agency-right-desicover-more::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    color:var(--main);
    transition: transform 0.4s ease-in-out;
  }
  
  .agency-right-desicover-more::after {
    content: "Discover more";
    bottom: 0;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .agency-right-desicover-more:hover::after {
    transform: translateX(100%);
  }
  /* .agency-right-desicover-more:hover {
    color: var(--main) !;
  }
   */


/* process bar style-start */
.processbar-main-container{



}

.processbar-main-container {

    align-items: center;
    justify-content: center;
    flex-direction: column;

   
}

.progress-container {
    width: 80%;
    max-width: 600px;
    margin-top: 0px;
 
}

.progress-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #0d0d0d;
    margin-bottom: 5px;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.progress-fill {
    width: 0%;
    height: 100%;
    background: linear-gradient(to right, #091e1e, #fbbc05);
    border-radius: 5px;
    animation: fillAnimation 2s ease-in-out forwards;
}

@keyframes fillAnimation {
    from {
        width: 0%;
    }
    to {
        width: 95%;
    }
}


@media(max-width:768px){
    .agency-right-text .p2{
        font-size: 35px;
        font-family: Syne;
        color: #04171A;
        font-weight: 600;
  
        margin: 4px 0px 0px;
    
        }
        .agency-right-middle-text{
        display: flex;
        flex-direction: column;
        }
}

@media (max-width:1200px) {



    .inner-best-agency{




     margin: auto;
   
     display: grid;
   /* grid-template-rows: 1fr 1fr; */
   grid-template-columns: 1fr;
   padding: 30px 0px;
     gap: 10px;
   
     
    }
}

