.marketing-tagscoll-container {
background-color: white;
    padding: 100px 0px;
    overflow: hidden;
}

.out-bg-marketing-tagscoll-container {
    background-color: var(--primary);
}

.inner-marketing-tagscoll-container {
    background-color: var(--text);
    transform: rotate(-4deg);
    padding: 20px;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
}

.marketing-tag-content-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    overflow: hidden;
}

.scroll-items {
    display: flex;
    gap: 2rem; /* Space between elements */
    animation: scrollText 15s linear infinite;
}

.scroll-items span {
    font-size: 22px;
    font-family: Syne;
    font-weight: 600;
    padding: 0px 20px;
}

/* Animation for smooth scrolling */
@keyframes scrollText {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
